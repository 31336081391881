import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Layout, Menu, Drawer, Grid, Tag, Badge, Spin, List, Row, Col, Popover, Typography } from 'antd';
const { Text } = Typography;

export default function NotificationContent(props) {

    const {
        isMobile,
        userData,
        notificationObject,
        setNotificationPopoverVisibleTrigger,
    } = props

    const nav = useNavigate();
    console.log("notificationObject: ", notificationObject);

    return (
        <>
            {isMobile ?
                <div style={{ width: '100%' }}>
                    <div style={{ maxHeight: '200px', overflowY: 'auto', paddingRight: '8px', paddingLeft: '8px' }}>
                        <List
                            dataSource={notificationObject || []}
                            renderItem={item => (
                                <>
                                    {item ?
                                        <List.Item>
                                            <Row style={{ width: '100%' }}>
                                                <Col span={1}>
                                                    {item.status === 'unread' && (
                                                        <div className="custom-badge-wrapper">
                                                            <Badge dot />
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col span={15}>
                                                    <Text>{item.text}</Text>
                                                </Col>
                                                <Col span={8} style={{ textAlign: 'right', color: '#8c8c8c', paddingRight: '16px' }}>
                                                    {item.time}
                                                </Col>
                                            </Row>
                                        </List.Item>
                                        :
                                        <>
                                        </>
                                    }

                                </>
                            )}
                        />
                    </div>
                    {isMobile ?
                        <></>
                        :
                        <div style={{ textAlign: 'right', padding: '8px', marginTop: '8px' }}>
                            <Button className="secondary-button button-size-default" onClick={setNotificationPopoverVisibleTrigger} style={{ marginRight: 8 }}>
                                Close
                            </Button>
                            <Button className="primary-button button-size-default">
                                Mark All Read
                            </Button>
                        </div>
                    }
                </div>
            :
                <div style={{ width: userData?.userType === "individualDoctor" ? '540px' : '660px' }}>
                    <div style={{ maxHeight: '200px', overflowY: 'auto', paddingRight: '8px', paddingLeft: '8px' }}>
                        <List
                            dataSource={notificationObject || []} // Fallback to an empty array
                            renderItem={item => (
                                <>
                                    {item ?
                                        <List.Item>
                                            <Row style={{ width: '100%' }}>
                                                <Col span={1}>
                                                    {item.status === 'unread' && (
                                                        <div className="custom-badge-wrapper">
                                                            <Badge dot />
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col span={15}>
                                                    <Text>{item.text}</Text>
                                                </Col>
                                                <Col span={8} style={{ textAlign: 'right', color: '#8c8c8c', paddingRight: '16px' }}>
                                                    {item.time}
                                                </Col>
                                            </Row>
                                        </List.Item>
                                        :
                                        <>
                                        </>
                                    }

                                </>
                            )}
                        />
                    </div>
                    {isMobile ?
                        <></>
                        :
                        <div style={{ textAlign: 'right', padding: '8px', marginTop: '8px' }}>
                            <Button className="secondary-button button-size-default" onClick={setNotificationPopoverVisibleTrigger} style={{ marginRight: 8 }}>
                                Close
                            </Button>
                            <Button className="primary-button button-size-default">
                                Mark All Read
                            </Button>
                        </div>
                    }
                </div>
            }
        </>
    );
};
