import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from "../lib/contextLib";
import "./RenderShiftList.css";
import { API } from "aws-amplify";
import { onError } from "../lib/errorLib";
import { useNavigate } from 'react-router-dom';
import { ClinicInfoCard_DoctorCalendar } from "../utilityComponents/profileComponents/profileComponents.js";

// Generic Modal Utility - confirm apply
import GenericModal from "../utilityComponents/GenericModal";

import { Button, Form, Input, notification, Select, Col, Modal, Row, Space, DatePicker, Tag, List, Card } from 'antd';

import {
    CheckCircleTwoTone,
    CloseCircleTwoTone
} from '@ant-design/icons';

export default function RenderShiftList(props) {
    const { isAuthenticated } = useAppContext();
    // const [selectedUserTypeValue, setSelectedUserDataValue] = useState(); //clinicTeamMember or individualDoctor
    // const [notificationMessage, setNotificationMessage] = useState(null);
    const navigate = useNavigate();

    // For Generic Modal Utility
    const [isGenericModalOpen, setIsGenericModalOpen] = useState(false); // generic modal - used in add clinic process.
    const [currentlySelectedShiftCard, setCurrentlySelectedShiftCard] = useState({});
    const [confirmApplyButtonLoading, setConfirmApplyButtonLoading] = useState(false);

    const [isClinicInformationModalOpen, setIsClinicInformationModalOpen] = useState(false);
    // const [currentlySelectedClinicInformation, setCurrentlySelectedClinicInformation] = useState();


    // notification modal
    const [api, contextHolder] = notification.useNotification();
    const { Option } = Select;

    const {
        isMobile,
        allDataForDoctorFindShiftsPage,
        loadingData,
        userData,
        onLoginEvent,
        filteredDataForDoctorFindShiftsPage,
        numberOfShiftsAvailable,
        filterteredListActive,
    } = props

    // If the user is a clinicTeamMember, take them to the 
    useEffect(() => {
        if (isAuthenticated && userData.userType === "clinicTeamMember") {
            navigate('/profile');
        }
    }, [isAuthenticated, userData.userType, navigate]);

    function LocalDateStringFromSimpleDate(dateString) {
        const date = new Date(`${dateString}T00:00:00`);

        // Convert the Date object to a localized string including the day of the week
        const localizedDateString = date.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        // console.log(localizedDateString); // Output: "Saturday, July 27, 2024" (in US English locale)
        return localizedDateString
    }

    function formatTimeRange(timeRange) {
        function convertTimeTo12HourFormat(time) {
            const [hour, minute] = time.split(':');
            const date = new Date();
            date.setHours(hour);
            date.setMinutes(minute);

            const options = {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            };

            return date.toLocaleTimeString('en-US', options);
        }

        const startTime = convertTimeTo12HourFormat(timeRange[0]);
        const endTime = convertTimeTo12HourFormat(timeRange[1]);
        return `${startTime} - ${endTime}`;
    }


    // clicked from confirm modal
    const applyToShift = async () => {

        setConfirmApplyButtonLoading(true);

        // Shift card is already open. Apply for this shift. Send API Call here.
        console.log("Send API Call to apply to this shift.");

        // Send API Call to add user as an "insterestedDoctor" on that specific open shift.
        // Making same API call as to createShfitData duting account creation, except only 
        // passing in interestedDoctor, to update an existing dynamodb shift item.
        // Need to also update shiftState of shift dynamo item to "pending".

        // Need to pass in more information to send along via email to clinic to let them know which shift a doctor has applied to.
        const doctorApplyToShiftParams = {
            clinicId: currentlySelectedShiftCard.clinicId,
            shiftId: currentlySelectedShiftCard.shiftId,
            interestedDoctorId: userData.userId,
            interestedDoctorName: userData.firstName + " " + userData.lastName,
            shiftDate: currentlySelectedShiftCard.shiftDate,
            clinicName: currentlySelectedShiftCard.clinicData.clinicName,
            clinicEmail: currentlySelectedShiftCard.clinicData.clinicEmail
        }

        console.log("doctorApplyToShiftParams: ", doctorApplyToShiftParams);
        const addInterestedDoctor = await createUserData(doctorApplyToShiftParams);
        console.log("addInterestedDoctor: ", addInterestedDoctor);

        // apply to shift
        async function createUserData(doctorApplyToShiftParams) {
            try {
                const response = await API.post("od-api", "/shiftData", { // createShiftData.js
                    body: doctorApplyToShiftParams,
                });

                console.log('API Response:', response);

                // Trigger Toast by updating Notification Message
                openNotification("Successfully applied to shift");
                setTimeout(() => openNotification("Shift added to your calendar"), 1000);
                setIsGenericModalOpen(false);
                setConfirmApplyButtonLoading(false);

                await onLoginEvent();
                afterApplyingToShiftRePullData();

                return response;
            } catch (e) {
                onError(e);
                console.log("ERROR: ", e);

                // Trigger Toast by updating Notification Message
                openNotification("Error applying to shift");
                setConfirmApplyButtonLoading(false);
            }
        }
    }

    const afterApplyingToShiftRePullData = () => {
        if (!filterteredListActive) {
            // After Apply Click, Set the card to say "Applied"
            // For some reason this is not working after filtering the list...
            // Find out why... it is not re-pulling in the new data of the filtered list
            const applyButton = document.querySelector(`Button[apply-button='${currentlySelectedShiftCard.shiftId + "_apply"}']`);
            const shiftStateTag = document.querySelector(`span[shift-state-tag='${currentlySelectedShiftCard.shiftId + "_tag"}']`);

            console.log("applyButton: ", applyButton);
            console.log("shiftStateTag: ", shiftStateTag);

            applyButton.setAttribute('disabled', true);
            applyButton.innerHTML = "Applied";

            // Update the tag from "Available" to "Pending", and update tag background color
            shiftStateTag.innerHTML = "PENDING";
            shiftStateTag.style.backgroundColor = '#fff7e6';
        } else {

            // REFACTOR at line 164, data is not getting re-pulled into the filtered list.
            setTimeout(() => window.location.reload(), 1000);
        }
    }

    // First Apply Click
    const shiftCardApplyClick = async (item) => {

        console.log("shift card apply clicked..");
        console.log("currentlySelectedShiftCard: ", item);

        // Open Confirm Modal
        setCurrentlySelectedShiftCard(item);
        setIsGenericModalOpen(true);
    };

    const handleCancel = () => {
        console.log("handle cancel");
        setIsGenericModalOpen(false);
    };

    const shiftCardCancelClick = (item) => {
        console.log("clicked cancel on item button: ", item);

        // This is how to dynamically effect dynamically created components.
        const cardDiv = document.querySelector(`div[data-shift-id='${item.shiftId}']`);
        const textArea = document.querySelector(`textarea[shift-text-area='${item.shiftId + "_textArea"}']`);
        const buttonsRow = document.querySelector(`div[shift-button-row='${item.shiftId + "_buttons"}']`);
        const cancelButton = document.querySelector(`Button[cancel-button='${item.shiftId + "_cancel"}']`);

        // If shift card is open close it
        if (cardDiv.style.height == '400px') {
            // close this card
            cardDiv.style.height = '220px';
            // textArea.style.display = 'none';
            textArea.parentNode.style.display = 'none'; // also need to grab parent
            buttonsRow.style.marginTop = '-34px';
            cancelButton.setAttribute('hidden', true);
        }
    }

    // toast notification
    const openNotification = (message) => {
        notification.open({
            message: message,
            icon: message === "Error Applying To Shift" ?
                <CloseCircleTwoTone style={{ color: '#108ee9' }} />
                :
                <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
        });
    };

    function closeClinicInformationModal() {
        setIsClinicInformationModalOpen(false);
    }

    const checkIfDoctorHasAppliedForThisShift = (insterestedDoctors) => {

        if (insterestedDoctors != undefined) {
            // console.log("insterestedDoctors: ", insterestedDoctors);
            for (var i = 0; i < insterestedDoctors.length; i++) {
                // console.log("insterestedDoctors[i].doctorId: ", insterestedDoctors[i].doctorId);
                if (insterestedDoctors[i].doctorId == userData.userId) {
                    return true
                }
            }
        }

        return false
    }

    const openClinicInformationModal = (currentlySelectedClinic) => {
        console.log("currentlySelectedClinic: ", currentlySelectedClinic);
        setCurrentlySelectedShiftCard(currentlySelectedClinic);
        setIsClinicInformationModalOpen(true);
    }

    // Input into JSX below...
    return (
        <>
            <Space direction="vertical" size="large" style={{ width: '100%', padding: isMobile ? '8px' : '16px' }}>
                <p style={{ marginLeft: '16px', marginBottom: '-16px' }} >{numberOfShiftsAvailable} shifts available</p>
                <div
                    style={{ height: 'calc(100vh - 280px)', overflowY: 'auto', width: '100%' }}>  {/* Scrollable container */}
                    <List
                        itemLayout="vertical"
                        dataSource={filterteredListActive ? filteredDataForDoctorFindShiftsPage : allDataForDoctorFindShiftsPage?.shiftData}
                        renderItem={(item) => (

                            // DO NOT LIST SHIFTS THAT ARE "SCHEDULED"
                            item.acceptedDoctor === undefined ?

                                // Card Starts Here - Hi Mal :)
                                <List.Item
                                    key={item.shiftId}
                                    style={{ borderBottom: 'none', paddingTop: '8px', paddingBottom: '8px' }}
                                >
                                    <Card data-shift-id={item.shiftId} style={{ border: 'none', height: '220px', marginLeft: '12px', marginRight: '12px', boxShadow: '0px 4px 16px 0px rgba(43, 58, 122, 0.16)' }}>
                                        <Row>
                                            <Col span={20}>
                                                <p style={{
                                                    marginTop: '-8px'
                                                }}>
                                                    {LocalDateStringFromSimpleDate(item.shiftDate)}
                                                </p>
                                                <p
                                                    style={{
                                                        color: '#667284',
                                                        marginTop: '-16px',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {formatTimeRange(item.shiftDoctorHours)}
                                                </p>
                                            </Col>
                                            <Col span={4}>
                                                <p style={{ float: 'right', marginTop: "-8px" }}>${item.shiftPay}</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ marginTop: '-4px', color: '#BFBFBF' }}></hr>
                                        <Row style={{ marginTop: '-2px' }}>
                                            <Col span={20}>
                                                {item.isGlassesAndContacts ? (
                                                    <Tag
                                                        color={'#F8EFFF'}
                                                        style={{ color: 'black', fontSize: '10px' }}
                                                    >
                                                        ROUTINE
                                                    </Tag>
                                                ) : null}
                                                {item.isMedical ? (
                                                    <Tag
                                                        color={'#F8EFFF'}
                                                        style={{ color: 'black', fontSize: '10px' }}
                                                    >
                                                        MEDICAL
                                                    </Tag>
                                                ) : null}
                                            </Col>
                                            <Col span={4}>
                                                {/* Shift State Tag in Shift Card ("Available", "Pending", "Scheduled") */}

                                                {/* TODO: Need to update this logic to work with nested objects. */}
                                                {/* We need to know if any of the nested objects in this array include this doctor.. */}
                                                <Tag
                                                    color={checkIfDoctorHasAppliedForThisShift(item?.interestedDoctors) ? '#fff7e6' : '#A6F8C1'}
                                                    shift-state-tag={item.shiftId + '_tag'}
                                                    style={{
                                                        color: 'black',
                                                        fontSize: '10px',
                                                        float: 'right',
                                                        marginRight: '0px',
                                                    }}
                                                >
                                                    {/* If this doctor has already applied to this shift, show "pending", but if not, show available. */}
                                                    {/* Need to make sure that it shows as available for other doctors who have not applied, rgardless that the  */}
                                                    {/* shiftState is "Pending" */}
                                                    {checkIfDoctorHasAppliedForThisShift(item?.interestedDoctors) ? item.shiftState.toUpperCase() : 'AVAILABLE'}
                                                </Tag>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '20px' }}>
                                            <Col span={24}>
                                                {/* <p style={{ marginTop: '-8px' }}>
                                                    {item.clinicData.clinicName}
                                                </p> */}
                                                <p style={{ marginTop: '-8px', cursor: 'pointer', color: '#1677ff' }}
                                                    onClick={() => openClinicInformationModal(item.clinicData)}
                                                    className="underline-on-hover"
                                                >
                                                    {item.clinicData.clinicName}
                                                </p>
                                                <p
                                                    style={{
                                                        color: '#667284',
                                                        marginTop: '-16px',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {item.clinicData.clinicAddress.split(',')[0]}
                                                </p>
                                                <p
                                                    style={{
                                                        color: '#667284',
                                                        marginTop: '-16px',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {item.clinicData.clinicAddress
                                                        .split(',')
                                                        .slice(1)
                                                        .join(',')}
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row
                                            shift-button-row={item.shiftId + '_buttons'}
                                            style={{ marginTop: '-52px' }}
                                        >
                                            <Col span={12}>
                                                <Button
                                                    className="cancelButton"
                                                    cancel-button={item.shiftId + '_cancel'}
                                                    hidden
                                                    style={{ marginTop: '0px', marginRight: '-3px' }}
                                                    onClick={() => shiftCardCancelClick(item)}
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                            <Col span={12}>
                                                <Button
                                                    apply-button={item.shiftId + '_apply'}
                                                    className="applyButton"
                                                    type="default"
                                                    disabled={checkIfDoctorHasAppliedForThisShift(item?.interestedDoctors) ? true : false}
                                                    style={{
                                                        float: 'right',
                                                        marginTop: '4px',
                                                        marginRight: '-3px',
                                                    }}
                                                    onClick={() => shiftCardApplyClick(item)}
                                                >
                                                    {/* If this doctor has already applied to this shift, don't let them apply again. */}
                                                    {checkIfDoctorHasAppliedForThisShift(item?.interestedDoctors) ? 'Applied' : 'Apply'}
                                                </Button>
                                                {/* DISPLAY SHIFT ID FOR DEBUGGING */}
                                                {/* <p
                                                            style={{
                                                            fontSize: '10px',
                                                            textAlign: 'right',
                                                            float: 'right',
                                                            marginRight: '0px',
                                                            marginTop: '-80px',
                                                            }}
                                                        >
                                                            {item.shiftId}
                                                        </p> */}
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                                :
                            <></>
                        )}
                    />
                </div>
            </Space>

            <GenericModal
                handleCancel={handleCancel}
                modalTitle={"Confirm Apply"}
                isGenericModalOpen={isGenericModalOpen}
                bodyText={"Are you sure you want to apply to this shift?"}
                showButton='true'
                showCancelButton='true'
                showLoadingSpinner={false}
                showButtonLoading={confirmApplyButtonLoading}
                buttonFunction={applyToShift}
                buttonText={"Apply"}
                zIndex={1000}
            ></GenericModal>

            {/* Clinic Information Modal - From Open Shift List */}
            {/* TODO: Finish this.... */}
            <Modal
                title="Clinic Information"
                centered
                open={isClinicInformationModalOpen}
                onOk={closeClinicInformationModal}
                onCancel={closeClinicInformationModal}
                footer={
                    <>
                        <Button className="primary-button button-size-default" onClick={closeClinicInformationModal}>
                            Close
                        </Button>
                    </>
                }
            >
                {/* <DoctorCard_CalendarInfo userData={currentlySelectedDoctorInformation}/> */}
                <ClinicInfoCard_DoctorCalendar clinicData={currentlySelectedShiftCard} />

                {/* TODO: Add clinic information here */}

            </Modal>
        </>
    );
}
