import React, { useState, useRef, useEffect } from "react";
import { onError } from "../../lib/errorLib";
import { v4 as uuidv4 } from 'uuid';
import { API } from "aws-amplify";
import dayjs from 'dayjs';
import "./ClinicInformation_Profile.css";
import AddressAutoComplete from "../../containers/AddressAutoComplete"
import { GoogleMap, useJsApiLoader, Marker, OverlayView } from '@react-google-maps/api';

import { Button, Modal, Form, Input, notification, Checkbox, TimePicker, Popover, Col, Row, Card, Typography} from 'antd';

import {
    CheckCircleTwoTone, CloseCircleTwoTone, InfoCircleOutlined, EditOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

export function ClinicInformation_Profile(props) {

    const { 
        // handleCancel,
        isMobile,
        userData,
        onLoginEvent, // refresh page after clinic is added.
        // setIsCreateClinicModalOpen,
        // isCreateClinicModalOpen,
        // setIsGenericModalOpen,
        clinic,
        index
    } = props

    const [clinicName, setClinicName] = useState(clinic.clinicName); 
    const [clinicWebsite, setClinicWebsite] = useState(clinic.clinicWebsite); 
    const [clinicAddress, setClinicAddress] = useState(clinic.clinicAddress); 
    const [clinicPhoneNumber, setClinicPhoneNumber] = useState(clinic.clinicPhoneNumber);
    const [clinicEmail, setClinicEmail] = useState(clinic.clinicEmail);
    const [clinicDoctorHours, setClinicDoctorHours] = useState(clinic.clinicDoctorHours);
    const [clinicDoctorHoursSimpleArray, setClinicDoctorHoursSimpleArray] = useState("");
    const [clinicPay, setClinicPay] = useState(clinic.clinicPay);
    const [clinicNumberOfExamLanes, setClinicNumberOfExamLanes] = useState(clinic.clinicNumberOfExamLanes);
    const [clinicGlassesContactsBool, setClinicGlassesContactsBool] = useState(clinic.clinicGlassesContactsBool);
    const [clinicMedicalBool, setClinicMedicalBool] = useState(clinic.clinicMedicalBool);
    const [clinicNotes, setClinicNotes] = useState(clinic.clinicNotes);

    // edit clinic button loading
    const [editClinicButtonLoading, setEditClinicButtonLoading] = useState(false);
    const [isEditClinicModalOpen, setIsEditClinicModalOpen] = useState(false);

    // toast notifications
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();

    const { TextArea } = Input;
    const inputRef = useRef(null); // for address autoComplete

    // console.log("clinic: ", clinic);
    // handleClinicDoctorHoursChange(clinic.clinicDoctorHours);

    function validateCreateClinic() {
        if(
            clinicName !== "" &&
            clinicWebsite !== "" &&
            clinicAddress !== "" &&
            clinicPhoneNumber !== "" &&
            clinicEmail !== "" &&
            clinicDoctorHours !== "" &&
            clinicPay !== "" &&
            clinicNumberOfExamLanes !== ""
            )
        {
            if(clinicGlassesContactsBool || clinicMedicalBool){
                return true;
            }
        }else{
            return false;
        }
    }

    // Authenticate Google Maps API
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const updateClinic = async () => {
        console.log("update clinic..");
        setEditClinicButtonLoading(true);
        let geocodedAddressResult = {};
        if(isLoaded){
            // Execute Geocoding here to get Lat Long and save out to dynamo for future use...
            const geocoder = new window.google.maps.Geocoder();
            const address = clinicAddress; // needs variable name address to work with google maps api..
        
            console.log("geocoding clinic address to save into dynamodb, and not need to geocode again downstream.");
            console.log("clinicAddress for geocoding: ", address);
            const geocodingResponse = await geocoder.geocode({address}); // this clinic address is not working...
            console.log("geocodingResponse: ", geocodingResponse); // full geocode object, dont need everything?
            if (geocodingResponse.results[0]) {
                const { lat, lng } = geocodingResponse.results[0].geometry.location;
                geocodedAddressResult = {
                    lat: lat(),
                    lng: lng(),
                    address,
                    clinicName: `${geocodingResponse.results[0].formatted_address.split(",")[0]}`,
                    metadata: `Clinic Info: ${address}`
                };
            }
        }
        console.log("geocoded adress results: ", geocodedAddressResult);

        // console.log("clinicDoctorHoursSimpleArray: ", clinicDoctorHoursSimpleArray);
        // console.log("clinic.clinicDoctorHours: ", clinic.clinicDoctorHours);

        // clinic data params
        const clinicDataParams = {
          orgId: clinic.orgId,
          clinicId: clinic.clinicId,
          clinicName: clinicName,
          clinicWebsite: clinicWebsite,
          clinicAddress: clinicAddress,
          clinicLat: geocodedAddressResult.lat,
          clinicLng: geocodedAddressResult.lng,
          clinicPhoneNumber: clinicPhoneNumber,
          clinicEmail: clinicEmail,
          clinicDoctorHours: clinicDoctorHoursSimpleArray === "" ? clinic.clinicDoctorHours : clinicDoctorHoursSimpleArray , // send simple time string array to dynamodb
          clinicPay: clinicPay,
          clinicNumberOfExamLanes: clinicNumberOfExamLanes,
          clinicGlassesContactsBool: clinicGlassesContactsBool,
          clinicMedicalBool: clinicMedicalBool,
          clinicNotes: clinicNotes
        }
        console.log("update clinicDataParams: ", clinicDataParams);
    
        // create clinic logic
        updateClinic_CallApi(clinicDataParams);
    };
    
    function updateClinic_CallApi(clinicData) {
        try {
            return API.put("od-api", "/clinicData", { // updateClinicData.js
                body: clinicData,
            }).then((data) => {
            console.log("update clinic data response: ", data);
            if(data.statusCode === 200){ // check response to see if succesful or not.
                openNotification('Updated Clinic Succesfully');
                const parsedBody = JSON.parse(data.body);
                console.log("parsedBody clinic creation: ", parsedBody);

                setIsEditClinicModalOpen(false);
                setEditClinicButtonLoading(false);

                // the page will not resfresh until we tell it to. Pull updated userData.
                onLoginEvent();
            }else{
                openNotification('Clinic Update Failed');
                setEditClinicButtonLoading(false);
                setIsEditClinicModalOpen(false);
            }
            });
        } catch (e) {
            onError(e);
            console.log("ERROR: ", e);
            setEditClinicButtonLoading(false);
        }
    };

    const openNotification = (message) => {
        api.open({
            message: message,
            icon: message === "Clinic Creation Failed" ? 
            <CloseCircleTwoTone style={{ color: '#108ee9' }} /> 
            : 
            <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
        });
    };
        
    // For Create New Clinic
    const handleClinicNameChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setClinicName(event.target.value);
    };

    const handleClinicWebsiteChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setClinicWebsite(event.target.value);
    };

    const handleClinicPhoneNumberChange = (event) => {
        // console.log("event.target.id: ", event.target.value);

        const formattedPhoneNumber = formatPhoneNumber(event.target.value)
        setClinicPhoneNumber(formattedPhoneNumber);
    };

    function formatPhoneNumber(value) {
        if (!value) return value;
      
        // Remove all non-numeric characters
        const phoneNumber = value.replace(/[^\d]/g, '');
      
        // Format the phone number
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) {
          return phoneNumber;
        } else if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        } else {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
        }
      }

    const handleClinicEmailChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setClinicEmail(event.target.value);
    };

    // format the doctor hours from iso to simple string array.
    const handleClinicDoctorHoursChange = (value) => {
        const adjustedValue = value.map(time => {
            const hours = dayjs(time).hour();
            const minutes = dayjs(time).format('mm');
            return `${hours}:${minutes}`;
        });
        console.log("adjustedValue: ", adjustedValue); 

        // this is only populated when the doctor hours change, that is why it is empty...

        setClinicDoctorHoursSimpleArray(adjustedValue); // to send to dynamo
        setClinicDoctorHours([dayjs(adjustedValue[0], 'HH:mm'), dayjs(adjustedValue[1], 'HH:mm')]);
    };

    const handleClinicPayChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setClinicPay(event.target.value);
    };

    const handleNumberOfExamLanesChange = (event) => {
        console.log("event.target.id: ", event.target.value);
        setClinicNumberOfExamLanes(event.target.value);
    };

    const InfoPopover = () => {
        return (
          <Popover
            content={
              <div style={{ maxWidth: '250px' }}>
                Set the doctor's usual hours here. You can always edit these hours here, or on the individual shifts.
              </div>
            }
            placement="right"
          >
            <Button
              type="text"
              shape="circle"
              icon={<InfoCircleOutlined style={{ color: '#1890ff', fontSize: '16px' }} />}
            />
          </Popover>
        );
      };
      const clinicExamTypes = (clinic) => {
        let clinicExamTypes = "";
        if(clinic.clinicGlassesContactsBool && !clinic.clinicMedicalBool){
          clinicExamTypes = "Routine"
        }else if(clinic.clinicMedicalBool && !clinic.clinicGlassesContactsBool){
          clinicExamTypes = "Medical"
        }else if(clinic.clinicGlassesContactsBool && clinic.clinicMedicalBool){
          clinicExamTypes = "Routine, Medical"
        }
        return clinicExamTypes
      };

      const clinicDefaultDoctorHours = (clinic) => {
        const startTime = clinic.clinicDoctorHours[0];
        const endTime = clinic.clinicDoctorHours[1];
        const clinicDoctorHours = convertMilitaryToStandardTime(startTime) + " - " + convertMilitaryToStandardTime(endTime) + " | $" +  clinic.clinicPay
    
        return clinicDoctorHours
      };

      function convertMilitaryToStandardTime(militaryTime) {
        // Split the input into hours and minutes
        const [hours, minutes] = militaryTime.split(':').map(Number);
        // Calculate the period (AM or PM)
        const period = hours >= 12 ? 'pm' : 'am';
        // Convert hours to 12-hour format
        const standardHours = hours % 12 || 12; // Converts 0 to 12 for midnight and 12-hour conversion
        // Format the final time string
        return `${standardHours}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
      }

      const closeModal = () => {
        setIsEditClinicModalOpen(false);
      }

      const openEditClinicModal = () => {
        setIsEditClinicModalOpen(true);
      }

    return(
        <>
            {contextHolder}
            <div 
                style={{ display: 'flex', justifyContent: 'center', width: '100%'}}
                key={index}
            >
                <Card
                    bordered={true}
                    style={{ width: isMobile ? '90%' : '75%', padding: '0px', marginBottom: '4px' }} 
                    // key={index}
                    >
                    <Row justify="space-between" align="middle">
                        <Col>
                        <Title level={5} style={{ marginBottom: '16px', marginTop: '-8px' }}>{clinic.clinicName}</Title>
                        </Col>
                        <Col>
                        <Button icon={<EditOutlined />} className="secondary-button button-size-default" onClick={openEditClinicModal} >Edit</Button>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginBottom: '-24px' }}>
                        <Col xs={24} sm={12}>
                        <Text style={{ color: '#667284' }}>Clinic address</Text>
                        <Paragraph>{clinic.clinicAddress}</Paragraph>

                        <Text style={{ color: '#667284' }}>Phone number</Text>
                        <Paragraph>{clinic.clinicPhoneNumber}</Paragraph>

                        {/* <Text style={{ color: '#667284' }}>Email address</Text>
                        <Paragraph>{clinic.email}</Paragraph> */}

                        <Text style={{ color: '#667284'}}>Website</Text>
                        <Paragraph style={{ marginBottom: '16px'}}>{clinic.clinicWebsite}</Paragraph>
                        </Col>

                        <Col xs={24} sm={12}>
                        <Text style={{ color: '#667284' }}>Exam types</Text>
                        <Paragraph>{(clinicExamTypes(clinic))}</Paragraph>
                        {/* <p>{(clinicExamTypes(clinic))}</p> */}

                        <Text style={{ color: '#667284' }}>Doctor hours and pay</Text>
                        <Paragraph>{(clinicDefaultDoctorHours(clinic))}</Paragraph>
                        {/* <p>{(clinicDefaultDoctorHours(clinic))}</p> */}

                        <Text style={{ color: '#667284' }}>Notes</Text>
                        <Paragraph>{clinic.clinicNotes}</Paragraph>
                        </Col>
                    </Row>
                </Card>

                <Modal
                    open={isEditClinicModalOpen}
                    title="Edit Clinic Information"
                    onCancel={closeModal}
                    destroyOnClose={true}  // Ensures the form component is destroyed when modal is closed - clears input fields..
                    footer={ 
                        <>
                            <Button key="closeModal" className="secondary-button button-size-default" onClick={closeModal}>
                                back
                            </Button>
                            <Button key="joinClinic" className="primary-button button-size-default" loading={editClinicButtonLoading} onClick={updateClinic} disabled={!validateCreateClinic()}>
                                Save
                            </Button>
                        </>

                    }
                >
                    <>
                        <Form 
                        layout="vertical"
                        autoComplete="off"
                        initialValues={{
                            clinicName: clinic.clinicName,
                            clinicWebsite: clinic.clinicWebsite,
                            clinicAddress: clinic.clinicAddress,
                            clinicPhoneNumber: clinic.clinicPhoneNumber,
                            clinicEmail: clinic.clinicEmail,
                            clinicDoctorHours: [
                                dayjs(clinic.clinicDoctorHours[0], 'HH:mm'), 
                                dayjs(clinic.clinicDoctorHours[1], 'HH:mm')
                            ],
                            clinicPay: clinic.clinicPay,
                            clinicNumberOfExamLanes: clinic.clinicNumberOfExamLanes,
                            clinicGlassesContactsBool: clinic.clinicGlassesContactsBool,
                            clinicMedicalBool: clinic.clinicMedicalBool,
                            clinicNotes: clinic.clinicNotes
                          }}
                        >
                            <Form.Item name="clinicName" label="Clinic Name" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                <Input placeholder="clinic name"
                                    type="text"
                                    style={{ marginTop: -12}} 
                                    value={clinicName}
                                    rules={[{ required: true }]}
                                    onChange={handleClinicNameChange}
                                    />
                            </Form.Item>
                            <Form.Item name="clinicWebsite" label="Website" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                <Input placeholder="website"
                                    type="text"
                                    style={{ marginTop: -12}} 
                                    value={clinicWebsite}
                                    rules={[{ required: true }]}
                                    onChange={handleClinicWebsiteChange}
                                    />
                            </Form.Item>
                            <Form.Item name="clinicAddress" label="Address" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                <AddressAutoComplete 
                                    setAddress={setClinicAddress}
                                    previousAddress={clinic.clinicAddress}
                                ></AddressAutoComplete>
                            </Form.Item>
                            <Form.Item name="clinicPhoneNumber" label="Phone Number" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                <Input placeholder="phone number"
                                    type="text"
                                    style={{ marginTop: -12}} 
                                    value={clinicPhoneNumber}
                                    rules={[{ required: true }]}
                                    onChange={handleClinicPhoneNumberChange}
                                    />
                            </Form.Item>
                            <Form.Item name="clinicEmail" label="Clinic Email" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                <Input placeholder="clinic email"
                                    type="text"
                                    style={{ marginTop: -12}} 
                                    value={clinicEmail}
                                    rules={[{ required: true }]}
                                    onChange={handleClinicEmailChange}
                                    />
                            </Form.Item>
                            <Form.Item
                                name="clinicDoctorHours"
                                label={
                                    <span>
                                    Doctor Hours 
                                    <InfoPopover />
                                    </span>
                                }
                                style={{ marginBottom: 4 }}
                                rules={[{ required: true }]}
                                >
                                    <TimePicker.RangePicker 
                                        needConfirm={false}
                                        style={{ marginTop: -12 }} 
                                        minuteStep={10}
                                        format="h:mm a"
                                        use12Hours
                                        onChange={(value) => {
                                            handleClinicDoctorHoursChange(value);
                                        }}
                                    />
                            </Form.Item>
                            <Form.Item colon={false} name="clinicPay" label="Pay (USD) $" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                <Input placeholder="($)"
                                    style={{ marginTop: -12}} 
                                    type="number"
                                    value={clinicPay}
                                    onChange={handleClinicPayChange}
                                    onWheel={(e) => e.target.blur()}
                                    />
                            </Form.Item>
                            <Form.Item colon={false} name="clinicNumberOfExamLanes" label="Number of Exam Lanes" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                <Input placeholder="number of exam lanes"
                                    style={{ marginTop: -12}} 
                                    type="number"
                                    value={clinicPay}
                                    onChange={handleNumberOfExamLanesChange}
                                    onWheel={(e) => e.target.blur()}
                                    />
                            </Form.Item>
                            <p style={{ fontSize: '14px', marginBottom: '-0px' }}>Exam Type</p>
                            <Form.Item name="clinicGlassesContactsBool">
                                <Checkbox 
                                    checked={clinicGlassesContactsBool}
                                    onChange={(e) => setClinicGlassesContactsBool(e.target.checked)}> 
                                    Routine (glasses and contacts)
                                </Checkbox>
                            </Form.Item>
                            <Form.Item style={{marginTop: -24}} name="clinicMedicalBool">
                                <Checkbox 
                                    checked={clinicMedicalBool}
                                    onChange={(e) => setClinicMedicalBool(e.target.checked)}> Medical optometry
                                </Checkbox>
                            </Form.Item>
                            <Form.Item style={{marginTop: -10}} name="clinicNotes" label="Add Notes:" >
                                <TextArea 
                                    rows={2} 
                                    placeholder="add notes" 
                                    style={{ marginTop: -2}} 
                                    value={clinicNotes}
                                    maxLength={600} 
                                    
                                    onChange={(e) => setClinicNotes(e.target.value)}
                                />
                            </Form.Item>
                        </Form>
                    </>
                </Modal>

            </div>
        </>

    );
}