import React, { useState } from "react";
import "./Profile.css";
import { Button, Form, Space, notification, Typography, Avatar, Card, Row, Col, Modal } from "antd";
import { EditOutlined, CheckCircleTwoTone, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import { API } from "aws-amplify";
import { onError } from "../lib/errorLib";
import {
  AvatarNameCard,
  PersonalInfoTitle, 
  PersonalInfo, 
  DoctorCard_ProfessionalTitle, 
  DoctorCard_ProfessionalInfo, 
  ClinicCard_ClinicTitle,
  DoctorCard_PreferencesTitle,
  DoctorCard_PreferencesInfo
} from "../utilityComponents/profileComponents/profileComponents.js";

// import { ClinicInformation_Profile } from "../utilityComponents/profileComponents/ClinicInformation_Profile.js"
import { ClinicInformation_Profile } from "../utilityComponents/profileComponents/ClinicInformation_Profile.js"

const { Title, Text, Paragraph } = Typography;

export default function Profile(props) {

  const { 
    isMobile, 
    currentPage,
    userData, 
    clinicData,
    OpenModal_CreateAnOrg, 
    OpenModal_JoinAnOrg, 
    orgData, 
    OpenModal_CreateAClinic, 
    onLoginEvent
  } = props

  return (
    <>
      {/* User Profiles */}
      <div className="page-background" style={{overflow: 'auto', display: 'flex', flexDirection: 'column', paddingTop: '40px', alignItems: 'center', height: 'calc(100vh - 104px)' }}>
        <AvatarNameCard userData={userData} orgData={orgData} isMobile={isMobile} />

        {/* <div style={{width: '70%' }}> 
          {userData.userType === "individualDoctor" ? 
            individualDoctorProfile 
          : 
            clinicTeamMemberProfile
          }
        </div> */}

        {/* TODO: Start on Edit Profile and Edit Clinic Information logic.... Modals pop out for editting.. */}

        {/* <br></br> */}
        <PersonalInfoTitle isMobile={isMobile}/>
        <PersonalInfo currentPage={currentPage} userData={userData} onLoginEvent={onLoginEvent} isMobile={isMobile} />
        <>
          {userData.userType == 'clinicTeamMember' ? 
            <>
              <ClinicCard_ClinicTitle OpenModal_CreateAClinic={OpenModal_CreateAClinic} isMobile={isMobile} />

              {/* Map - dynamically create each clinic card  */}
              {clinicData && clinicData.length > 0 ? (
                <>
                  {clinicData.map((clinic, index) => (

                    <ClinicInformation_Profile onLoginEvent={onLoginEvent} isMobile={isMobile} key={index} clinic={clinic}/>

                  ))}
                </>
              ) : (
                <p style={{marginTop: '26px'}}>No clinics available</p>
              )}
              <br></br>
            </>
          : 
            <>
              {/* <DoctorCard_PersonalInfo/> */}
              <DoctorCard_ProfessionalTitle isMobile={isMobile} />
              <DoctorCard_ProfessionalInfo userData={userData} isMobile={isMobile} onLoginEvent={onLoginEvent}/>
              <DoctorCard_PreferencesTitle isMobile={isMobile}/>
              <DoctorCard_PreferencesInfo userData={userData} isMobile={isMobile} onLoginEvent={onLoginEvent} />
              {/* <DoctorCard_RecurringAvailability /> */}
            </>
          }
        </>
      </div>
    </>

  );
}