import React from 'react';
import { Row, Col, Card } from 'antd';
import "./LandingPagePhotos.css";

const LandingPagePhotos = (props) => {

    const { 
        isMobile
    } = props

  return (
    <div >
        <div>
            {isMobile ? 
                <Card className="custom-card">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8} className="order-1-xs order-4-md openSans">
                            <h3 className='openSans' style={{marginTop: isMobile ? "0px" : "0px"}}>As a Doctor</h3>
                            <p className="secondary-paragraph">Search the list of open shifts. Filter by location, job type, and pay. Build your schedule, get work, and get paid.</p>
                        </Col>
                        <Col xs={24} md={16} className="order-2-xs order-5-md">
                            <img 
                                src={`${process.env.PUBLIC_URL}/laptopAndPhone_mobile.png`}
                                alt="splash2" 
                                width="120%"
                                className= {isMobile ? "blockMobile" : "blockDesktop"} 
                            />
                        </Col>
                    </Row>
                </Card>
            : 
                <Row justify="center" gutter={[16, 16]}>
                    <Col xs={24} md={20} className="order-2-xs order-3-md">
                        <div>
                            <img 
                                src={`${process.env.PUBLIC_URL}/laptopAndPhone.png`}
                                alt="splash3"
                                width="100%"
                                className= {isMobile ? "blockMobile" : "blockDesktop"} 
                            />
                            <div style={{position: isMobile ? 'relative' : 'relative', marginTop: isMobile ? '-20px' : '-50px' , marginBottom: '120px', width: isMobile ? '100%' : '1000px'}}>
                                <h3 className='openSans'>As a Doctor</h3>
                                <p className="secondary-paragraph">Search the list of open shifts. Filter by location, job type, and pay. Build your schedule, get work, and get paid.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            }
        </div>

        <Card className="custom-card">
            <Row gutter={[16, 16]}>
                <Col xs={24} md={8} className="order-1-xs order-4-md openSans">
                    <h3 className='openSans' style={{marginTop: isMobile ? "0px" : "0px"}}>As a Clinic Owner or Manager</h3>
                    <p className="secondary-paragraph">Post open shifts when you need to find a doctor. Create a single shift, or make it recurring. Find the right doctor for your clinic, fast.</p>
                </Col>
                <Col xs={24} md={16} className="order-2-xs order-5-md">
                    <img 
                        src={isMobile ? `${process.env.PUBLIC_URL}/splash_1_mobile.png` : `${process.env.PUBLIC_URL}/splash_1.png`} 
                        alt="splash2" 
                        width={isMobile ? "124%" : "100%" }
                        className= {isMobile ? "blockMobile" : "blockDesktop"} 
                    />
                </Col>
            </Row>
        </Card>
        <Card className="custom-card">
            <Row gutter={[16, 16]}>
                <Col xs={24} md={8} className="order-1-xs order-4-md openSans">
                    <h3 className='openSans' style={{marginTop: isMobile ? "0px" : "0px"}}>Keep Track of Your Shifts</h3>
                    <p className="secondary-paragraph">The calendar helps you stay organized and manage your upcoming shifts. Get rid of the headache.</p>
                </Col>
                <Col xs={24} md={16} className="order-2-xs order-5-md">
                    <img 
                        src={isMobile ? `${process.env.PUBLIC_URL}/splash_2_mobile.png` : `${process.env.PUBLIC_URL}/splash_2.png`}
                        alt="splash2" 
                        width={isMobile ? "124%" : "100%" }
                        className= {isMobile ? "blockMobile" : "blockDesktop"} 
                    />
                </Col>
            </Row>
        </Card>
        <br></br>
    </div>
  );
};

export default LandingPagePhotos;