import React, { useState } from "react";
// import { Row, Col } from 'antd';
import { Button, Form, Input, notification, Select, Checkbox } from 'antd';
import { API } from "aws-amplify";
import { v4 as uuidv4 } from 'uuid';
import { onError } from "../lib/errorLib";
import "./JoinWaitlistInputFields.css";

import {
    CheckCircleTwoTone,
    CloseCircleTwoTone 
  } from '@ant-design/icons';

const JoinWaitlistInputFields = (props) => {
    const [selectedUserTypeValue, setSelectedUserDataValue] = useState(); //clinicTeamMember or individualDoctor
    const [emailAddress, setEmailAddress] = useState("");
    const [joinBetaListBool, setJoinBetaListBool] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [api, contextHolder] = notification.useNotification();

    const { 
        Option,
        isMobile
    } = props

    function validateJoinWaitList() {
        if(
          emailAddress !== "" &&
          emailAddress.includes("@") &&
          emailAddress.includes(".") &&
          selectedUserTypeValue !== undefined
            )
        {
            return true;
        }else{
            return false;
        }
    }

    const onUserTypeChange = (value) => {
        switch (value) {
          case 'clinicTeamMember':
            setSelectedUserDataValue("clinicTeamMember");
            break;
          case 'individualDoctor':
            setSelectedUserDataValue("individualDoctor");
            break;
          default:
        }
    };

    function joinWaitlist(){
        joinWaitlistAPI();
        setIsLoading(true);
      }
    
      const joinWaitlistAPI = () => {
        const waitListData = {
          waitListId: uuidv4(),
          userType: selectedUserTypeValue,
          email: emailAddress,
          betaInterest: joinBetaListBool
        }
        console.log("waitListData: ", waitListData);
        createWaitListItem_CallApi(waitListData);
    };

    function createWaitListItem_CallApi(waitListData) {
        try {
            return API.post("od-api", "/waitList", {
                body: waitListData,
            }).then((data) => {
            console.log("create wait list data response: ", data);
            if(data.statusCode === 200){ // check response to see if succesful or not.
                openNotification('Successfully added to the wait list!');
                const parsedBody = JSON.parse(data.body);
                console.log("parsedBody wait list creation: ", parsedBody);
               
                // do other things after successfully joined wait list
                setIsLoading(false);
    
            }else{
                openNotification('Wait List Creation Failed');
            }
            });
        } catch (e) {
            onError(e);
            console.log("ERROR: ", e);
        }
    };

    const handleJoinWaitlistInputChange = (event) => {
        setEmailAddress(event.target.value);
    };

    const openNotification = (message) => {
        api.open({
            message: message,
            icon: message === "Clinic Creation Failed" ? 
            <CloseCircleTwoTone style={{ color: '#108ee9' }} /> 
            : 
            <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
        });
    };

  return (
    <>
        {contextHolder}
        <p style={{marginBottom:'16px'}}>Join the waitlist! <span style={{marginLeft:'0px', fontSize: '24px'}}>🚀 👓</span></p> 
        <Form>
            <Form.Item style={{marginBottom:'20px'}} name="email" rules={[{ required: true }]}>
                <Input 
                    placeholder="Email address"
                    size="large"
                    className="inputField"
                    type="text"
                    value={emailAddress}
                    rules={[{ required: true }]}
                    onChange={handleJoinWaitlistInputChange}
                />
            </Form.Item>

            <Form.Item style={{marginBottom:'16px'}} name="User Type" rules={[{ required: true }]}>
                <Select
                    placeholder="Who are you?"
                    size="large"
                    onChange={onUserTypeChange}
                    className="inputField"
                    allowClear
                >
                    <Option value="clinicTeamMember" >I'm a clinic looking to hire doctors</Option>
                    <Option value="individualDoctor" >I'm a doctor looking for work</Option>
                </Select>
            </Form.Item>

            <Form.Item>
                <Checkbox 
                    checked={joinBetaListBool}
                    onChange={(e) => setJoinBetaListBool(e.target.checked)}
                    className="custom-checkbox"
                    style={{ fontSize: '16px', alignItems: 'center', transform: 'scale(1.5)', marginLeft: isMobile ? '0vw' : '0vw' }}
                > 
                    <span><p style={{ marginLeft: '2px', fontSize: isMobile ? '10px' : '10.3px', marginBottom: '0px'}}>I'm interested in becoming an early tester</p></span>
                </Checkbox>
            </Form.Item>

            <Button 
                key="joinWaitlist" 
                className="primary-button button-size-large"
                onClick={joinWaitlist} 
                disabled={!validateJoinWaitList()}
                loading={isLoading}
            >
                Join Waitlist
            </Button>

        </Form>
    </>
  );
};

export default JoinWaitlistInputFields;