import React, { useState } from "react";
import { Button, Modal, Form, Input } from 'antd';
import { useNavigate } from "react-router-dom";

function SignUpBlockerModal() {

    const [betaTesterCode, setBetaTesterCode] = useState("");
    const [modalOpenState, setModalOpenState] = useState(true);
    const nav = useNavigate();

    // For Join Existing Org
    const handleBetaTesterCodeChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setBetaTesterCode(event.target.value);
    };

    const joinWaitlist = () => {
        // send them to the landing page
        nav("/");
    };

    const confirmCode = () => {
        const codeToMatch = "eyes2020";
        if(betaTesterCode === codeToMatch){
            // close this modal
            setModalOpenState(false);
        }
        else
        {
            console.log("that code doesn't match.");
        }
    };

    return(
        <>
            <Modal
                open={modalOpenState}
                title={"Preparing for Launch  🚀 👓"}
                onCancel={joinWaitlist}
                footer={ 
                    <>
                        <Button key="joinWaitlist" className="secondary-button button-size-large" onClick={joinWaitlist}>
                            Back
                        </Button>
                        <Button key="confirmCode" className="primary-button button-size-large" onClick={confirmCode}>
                            Confirm Code
                        </Button>
                    </>
                }
            >
                <Form>
                    <p>Until we complete the intial version of OD Shift, we're only allowing early testers to join. If you'd like to become an early tester, please reach out! info.odshift@gmail.com </p>
                    <Form.Item name="tester code" label="Tester Code:" rules={[{ required: true }]}>
                    <Input placeholder="beta tester code"
                        size="large"
                        type="text"
                        value={betaTesterCode}
                        rules={[{ required: true }]}
                        onChange={handleBetaTesterCodeChange}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>

    );
}

export default SignUpBlockerModal

