import React, { useState, useRef, useEffect } from "react";
import "./PrivacyPolicy.css";
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from 'antd';
const { Title, Text, Paragraph } = Typography;

export default function PrivacyPolicy(props) {
  const navigate = useNavigate();

  const { 
    isMobile,
  } = props

  return (
    <div className="PrivacyPolicy">

      <div style={{
        width: isMobile ? '100%' : '80vw',
        paddingLeft: isMobile ? '20px' : '15vw',
        paddingRight: isMobile ? '20px' : '0px'
      }}>
        <h3 style={{ marginTop: isMobile ? '40px' : '60px', float: 'left', marginBottom: '40px' }}>Privacy Policy</h3>
        <div className="modal-content">
          <Paragraph className="last-updated">Last updated: 11-15-2024</Paragraph>

          <Paragraph>
            Welcome to OD Shift. By using our app, you agree to these Terms and Conditions ("Terms"). Please read them carefully.
          </Paragraph>

          <Title level={5} className="section-title">1. Acceptance of Terms</Title>
          <Paragraph>
            By downloading, installing, or using OD Shift, you agree to be bound by these Terms. If you do not agree to these Terms, you must not use the app.
          </Paragraph>

          <Title level={5} className="section-title">2. Use of the App</Title>
          <Paragraph>
            2.1. You agree to use the app only for lawful purposes and in accordance with these Terms.
          </Paragraph>
          <Paragraph>
            2.2. You are responsible for maintaining the confidentiality of your login credentials and are fully responsible for any activities that occur under your account.
          </Paragraph>

          <Title level={5} className="section-title">3. Personal Data Collection and Use</Title>
          <Paragraph>
            3.1. The app may collect personal data, including but not limited to your name, email address, and usage data, to provide and improve the service.
          </Paragraph>
          <Paragraph>
            3.2. Your data is stored securely and will not be shared with any third parties.
          </Paragraph>
          <Paragraph>
            3.3. We use the data to:
            <ul>
              <li>Provide the app's services and features</li>
              <li>Improve and optimize the app</li>
              <li>Respond to your requests or questions</li>
            </ul>
          </Paragraph>
          <Paragraph>
            3.4. You have the right to access, modify, or delete your personal data by contacting us at info.odshift@gmail.com.
          </Paragraph>

          <Title level={5} className="section-title">4. User Content</Title>
          <Paragraph>
            4.1. You may submit content to the app, such as messages or other information. You retain ownership of any intellectual property rights in your content.
          </Paragraph>
          <Paragraph>
            4.2. By submitting content, you grant us a worldwide, non-exclusive, royalty-free license to use, modify, and display your content solely for the purpose of providing the app's services.
          </Paragraph>
          <Paragraph>
            4.3. We reserve the right to remove any content that violates these Terms or is deemed inappropriate.
          </Paragraph>

          <Title level={5} className="section-title">5. Intellectual Property</Title>
          <Paragraph>
            5.1. All rights, titles, and interests in the app and its content, including but not limited to software, text, images, and logos, are owned by OD Shift or its licensors.
          </Paragraph>
          <Paragraph>
            5.2. You may not copy, modify, distribute, or create derivative works from the app without our express written consent.
          </Paragraph>

          <Title level={5} className="section-title">6. Limitation of Liability</Title>
          <Paragraph>
            6.1. To the fullest extent permitted by law, OD Shift and its affiliates, officers, and employees shall not be liable for any damages arising out of your use of the app.
          </Paragraph>
          <Paragraph>
            6.2. The app is provided "as is" without any warranties, either express or implied.
          </Paragraph>
          <Paragraph>
            6.3. OD Shift is not responsible for mistakes made by doctors or clinics for shift scheduling, missed shifts, and is not responsible for independent payment between both parties.
          </Paragraph>

          <Title level={5} className="section-title">7. Changes to the Terms</Title>
          <Paragraph>
            7.1. We reserve the right to update these Terms from time to time. Any changes will be posted within the app, and the "last updated" date will be revised.
          </Paragraph>
          <Paragraph>
            7.2. Your continued use of the app after changes are posted constitutes your acceptance of the updated Terms.
          </Paragraph>

          <Title level={5} className="section-title">8. Termination</Title>
          <Paragraph>
            8.1. We may suspend or terminate your access to the app at any time, for any reason, without notice.
          </Paragraph>
          <Paragraph>
            8.2. Upon termination, your right to use the app will cease immediately.
          </Paragraph>

          <Title level={5} className="section-title">9. Governing Law</Title>
          <Paragraph>
            These Terms are governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.
          </Paragraph>

          <Title level={5} className="section-title">10. Contact Us</Title>
          <Paragraph>
            If you have any questions about these Terms, please contact us at info.odshift@gmail.com.
          </Paragraph>
        </div>

      </div>

      <br></br>


    </div>
  );
}