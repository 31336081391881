import React, { useState, useRef, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../lib/contextLib";
import "./Home.css";
import LandingPagePhotos from "../utilityComponents/LandingPagePhotos";
import JoinWaitlistInputFields from "../utilityComponents/JoinWaitlistInputFields";
import { API } from "aws-amplify";
import { onError } from "../lib/errorLib";
import { useNavigate } from 'react-router-dom';
import { ClinicInfoCard_DoctorCalendar } from "../utilityComponents/profileComponents/profileComponents.js";
import RenderShiftList from "../components/RenderShiftList.js"

// Generic Modal Utility - confirm apply
import GenericModal from "../utilityComponents/GenericModal";

import { Button, Form, Input, notification, Select, Col, Modal, Row, Space, DatePicker, Tag, List, Card } from 'antd';
import GoogleMapOpto from "../components/GoogleMapOpto";

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone } from '@ant-design/icons';

export default function Home(props) {
  const { isAuthenticated } = useAppContext();
  // const [selectedUserTypeValue, setSelectedUserDataValue] = useState(); //clinicTeamMember or individualDoctor
    // const [notificationMessage, setNotificationMessage] = useState(null);
  const navigate = useNavigate();

  // For Generic Modal Utility
  const [isGenericModalOpen, setIsGenericModalOpen] = useState(false); // generic modal - used in add clinic process.
  const [currentlySelectedShiftCard, setCurrentlySelectedShiftCard] = useState({});
  const [confirmApplyButtonLoading, setConfirmApplyButtonLoading] = useState(false);

  // For Filtering
  const [filterteredListActive, setFilterteredListActive] = useState(false);
  const [filteredDataForDoctorFindShiftsPage, setFilteredDataForDoctorFindShiftsPage] = useState();
  const [numberOfShiftsAvailable, SetNumberOfShiftsAvailable] = useState(0);
  const [dates, setDates] = useState([]);

  const [isClinicInformationModalOpen, setIsClinicInformationModalOpen] = useState(false);
  // const [currentlySelectedClinicInformation, setCurrentlySelectedClinicInformation] = useState();


  // notification modal
  const [api, contextHolder] = notification.useNotification();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const { 
    isMobile,
    allDataForDoctorFindShiftsPage,
    loadingData,
    userData,
    onLoginEvent,
  } = props

  // If the user is a clinicTeamMember, take them to the 
  useEffect(() => {
    if (isAuthenticated && userData.userType === "clinicTeamMember") {
      navigate('/profile');
    }
  }, [isAuthenticated, userData.userType, navigate]);

  // If not filtering the shift list, get the number of available or pending shifts, excluding scheduled shifts.
  useEffect(() => {
    if(!filterteredListActive){
      let shiftCountExcludingScheduledShifts = 0;
      for(var i=0; i<allDataForDoctorFindShiftsPage?.shiftData?.length; i++){
        if(allDataForDoctorFindShiftsPage?.shiftData[i]?.acceptedDoctor === undefined){

          shiftCountExcludingScheduledShifts++
        }
      }
      SetNumberOfShiftsAvailable(shiftCountExcludingScheduledShifts);
    }

  }, [allDataForDoctorFindShiftsPage]);

  function LocalDateStringFromSimpleDate(dateString) {
    const date = new Date(`${dateString}T00:00:00`);

    // Convert the Date object to a localized string including the day of the week
    const localizedDateString = date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    
    // console.log(localizedDateString); // Output: "Saturday, July 27, 2024" (in US English locale)
    return localizedDateString
  }

  function formatTimeRange(timeRange) {
    function convertTimeTo12HourFormat(time) {
      const [hour, minute] = time.split(':');
      const date = new Date();
      date.setHours(hour);
      date.setMinutes(minute);
  
      const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
  
      return date.toLocaleTimeString('en-US', options);
    }
  
    const startTime = convertTimeTo12HourFormat(timeRange[0]);
    const endTime = convertTimeTo12HourFormat(timeRange[1]);
    return `${startTime} - ${endTime}`;
  }


  // Function to handle the date change event
  const onDateChange = (dates, dateStrings) => {

    setFilterteredListActive(true);

    // 'dates' is an array of moment objects
    // 'dateStrings' is an array of formatted date strings
    console.log('Selected Dates: ', dates);
    setDates(dates);
    console.log('Formatted Selected Dates: ', dateStrings);

    // WHEN A DATE RANGE FILTER IS CLEARED //
    if(dates === null){

      console.log("handling logic after clearing date range filter.");
      setFilterteredListActive(false);

      // AFTER CLEARING DATE RANGE FILTER
      // Set SetNumberOfShiftsAvailable after clearing the date range filter
      let shiftCountExcludingScheduledShifts = 0;
      console.log("allDataForDoctorFindShiftsPage?.shiftData: ", allDataForDoctorFindShiftsPage?.shiftData);
      for(var i=0; i<allDataForDoctorFindShiftsPage?.shiftData?.length; i++){
        if(allDataForDoctorFindShiftsPage?.shiftData[i]?.acceptedDoctor === undefined){
          shiftCountExcludingScheduledShifts++
        }
      }
      console.log("shiftCountExcludingScheduledShifts: ", shiftCountExcludingScheduledShifts);
      SetNumberOfShiftsAvailable(shiftCountExcludingScheduledShifts);
    }else{

      // WHEN A DATE RANGE IS PICKED //
      // filter the list to only show shifts within that date range.
      // Check if there is no filter applied, if not, use allDataForDoctorFindShiftsPage
      // If so, pass a filtered version of the items returned in that allDataForDoctorFindShiftsPage object..

      // console.log("allDataForDoctorFindShiftsPage: ", allDataForDoctorFindShiftsPage);
      let tempFilteredDataForDoctorFindShiftsPage = [];
      for(var i=0; i< allDataForDoctorFindShiftsPage?.shiftData?.length; i++){
        // if(allDataForDoctorFindShiftsPage?.shiftData[i]?.shiftDate ){ // how to check if a dates is within a given range.
        if(isDateInRange(allDataForDoctorFindShiftsPage?.shiftData[i].shiftDate, dateStrings[0], dateStrings[1]) 
          && allDataForDoctorFindShiftsPage?.shiftData[i].shiftState !== "Scheduled"){
          // Loop through all the items and check if they are within the selected range...
          console.log("shift found within the selected date range: ", allDataForDoctorFindShiftsPage?.shiftData[i]);
          tempFilteredDataForDoctorFindShiftsPage.push(allDataForDoctorFindShiftsPage?.shiftData[i]);
        }
      }

      // console.log("------ Filtered Shifts --------");
      console.log("filteredDataForDoctorFindShiftsPage", tempFilteredDataForDoctorFindShiftsPage);
      SetNumberOfShiftsAvailable(tempFilteredDataForDoctorFindShiftsPage?.length);

      // REFACTOR.. when filtering the list, new data is not pulling into the list, because this only happens when the date range is selected above.
      setFilteredDataForDoctorFindShiftsPage(tempFilteredDataForDoctorFindShiftsPage);
    }
  };

  // Utility function to check if a date is within a range
  function isDateInRange(date, startDate, endDate) {
    // Convert date strings to Date objects
    const targetDate = new Date(date);
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Check if the targetDate is between start and end dates (inclusive)
    return targetDate >= start && targetDate <= end;
  }

  // clicked from confirm modal
  const applyToShift = async () => {

    setConfirmApplyButtonLoading(true);

    // Shift card is already open. Apply for this shift. Send API Call here.
    console.log("Send API Call to apply to this shift.");

    // Send API Call to add user as an "insterestedDoctor" on that specific open shift.
    // Making same API call as to createShfitData duting account creation, except only 
    // passing in interestedDoctor, to update an existing dynamodb shift item.
    // Need to also update shiftState of shift dynamo item to "pending".

    // Need to pass in more information to send along via email to clinic to let them know which shift a doctor has applied to.
    const doctorApplyToShiftParams = {
      clinicId: currentlySelectedShiftCard.clinicId, 
      shiftId: currentlySelectedShiftCard.shiftId, 
      interestedDoctorId: userData.userId, 
      interestedDoctorName: userData.firstName + " " + userData.lastName,
      shiftDate: currentlySelectedShiftCard.shiftDate,
      clinicName: currentlySelectedShiftCard.clinicData.clinicName,
      clinicEmail: currentlySelectedShiftCard.clinicData.clinicEmail
    }

    console.log("doctorApplyToShiftParams: ", doctorApplyToShiftParams);
    const addInterestedDoctor = await createUserData(doctorApplyToShiftParams);
    console.log("addInterestedDoctor: ", addInterestedDoctor);

    // apply to shift
    async function createUserData(doctorApplyToShiftParams) {
      try {
        const response = await API.post("od-api", "/shiftData", { // createShiftData.js
          body: doctorApplyToShiftParams,
        });
  
        console.log('API Response:', response);
  
        // Trigger Toast by updating Notification Message
        openNotification("Successfully applied to shift");
        setTimeout(() => openNotification("Shift added to your calendar"), 1000);
        setIsGenericModalOpen(false);
        setConfirmApplyButtonLoading(false);

        await onLoginEvent();
        afterApplyingToShiftRePullData();

        return response;
      } catch (e) {
        onError(e);
        console.log("ERROR: ", e);
  
        // Trigger Toast by updating Notification Message
        openNotification("Error applying to shift");
        setConfirmApplyButtonLoading(false);
      }
    }
  }

  const afterApplyingToShiftRePullData = () => {
    if (!filterteredListActive) {
      // After Apply Click, Set the card to say "Applied"
      // For some reason this is not working after filtering the list...
      // Find out why... it is not re-pulling in the new data of the filtered list
      const applyButton = document.querySelector(`Button[apply-button='${currentlySelectedShiftCard.shiftId + "_apply"}']`);
      const shiftStateTag = document.querySelector(`span[shift-state-tag='${currentlySelectedShiftCard.shiftId + "_tag"}']`);

      console.log("applyButton: ", applyButton);
      console.log("shiftStateTag: ", shiftStateTag);

      applyButton.setAttribute('disabled', true);
      applyButton.innerHTML = "Applied";

      // Update the tag from "Available" to "Pending", and update tag background color
      shiftStateTag.innerHTML = "PENDING";
      shiftStateTag.style.backgroundColor = '#fff7e6';
    } else {

      // REFACTOR at line 164, data is not getting re-pulled into the filtered list.
      setTimeout(() => window.location.reload(), 1000);
    }
  }

  // First Apply Click
  const shiftCardApplyClick = async ( item ) => {

    console.log("shift card apply clicked..");
    console.log("currentlySelectedShiftCard: ", item);

    // Open Confirm Modal
    setCurrentlySelectedShiftCard(item);
    setIsGenericModalOpen(true);
  };

  const handleCancel = () => {
    console.log("handle cancel");
    setIsGenericModalOpen(false);
  };

  const shiftCardCancelClick = ( item ) => {
    console.log("clicked cancel on item button: ", item);

    // This is how to dynamically effect dynamically created components.
    const cardDiv = document.querySelector(`div[data-shift-id='${item.shiftId}']`);
    const textArea = document.querySelector(`textarea[shift-text-area='${item.shiftId + "_textArea"}']`);
    const buttonsRow = document.querySelector(`div[shift-button-row='${item.shiftId + "_buttons"}']`);
    const cancelButton = document.querySelector(`Button[cancel-button='${item.shiftId + "_cancel"}']`);

    // If shift card is open close it
    if(cardDiv.style.height == '400px'){
      // close this card
      cardDiv.style.height = '220px';
      // textArea.style.display = 'none';
      textArea.parentNode.style.display = 'none'; // also need to grab parent
      buttonsRow.style.marginTop = '-34px';
      cancelButton.setAttribute('hidden', true);
    }
  }

  // toast notification
  const openNotification = (message) => {
    notification.open({
        message: message,
        icon: message === "Error Applying To Shift" ? 
          <CloseCircleTwoTone style={{ color: '#108ee9' }} /> 
        : 
          <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
    });
  };

  function closeClinicInformationModal() {
    setIsClinicInformationModalOpen(false);
  }

  const TopFilters = () => {
    return(
      <>
        <h5 style={{marginLeft: '20px', marginTop: '20px', marginBottom: '-10px'}}>Find Shifts</h5>
        <br></br>
        {/* TODO: Add button to clear the filters. */}

        {/* Filter Form */}
        <Form layout="inline" style={{marginLeft: '20px', marginBottom: isMobile ? '4px' : '-10px'}}>
          <Form.Item label="Date range" layout="vertical">
            <RangePicker
              value={dates}  // Use the 'dates' state as the value
              onChange={onDateChange}  // Update state on change
              format="YYYY-MM-DD"  // Format to display the dates
              style={{ marginTop: '-6px' }}
            />

          </Form.Item>
          {isMobile ? 
            <></>
          : 
            <>
              <Form.Item label="Job type" layout="vertical">
                <Select placeholder="Fill-in" style={{ width: 120, marginTop: '-6px' }}>
                  <Option value="fillIn">Fill-in</Option>
                  <Option disabled value="partTime">Part-time</Option>
                  <Option disabled value="permanent">Permanent</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Exam type" layout="vertical">
                <Select placeholder="All" style={{ width: 120, marginTop: '-6px' }}>
                  <Option value="all">All</Option>
                  <Option disabled value="routine">Routine</Option>
                  <Option disabled value="medical">Medical</Option>
                </Select>
              </Form.Item>
            </>
          }

        </Form>
      </>
    )
  }


  // LANDING PAGE - Join Waitlist
  // This is what will show if you are not logged in at the root domain path
  function renderLandingPage() {
    return (
      <>
        {contextHolder}
        <div className="lander" style={{ marginLeft: isMobile ? '0px' : '32vw', width: isMobile ? '100%' : '30vw'}}>
          <h1 style={{ marginTop: isMobile ? '80px' : '100px'}}>OD Shift</h1>
          <p className="secondary-paragraph">Connecting optometrists and clinics.</p>
          <div style={{ marginTop: '60px', marginBottom: '30px'}}>
            <JoinWaitlistInputFields 
              Option={Option}
              isMobile={isMobile} 
            ></JoinWaitlistInputFields>
          </div>
          <br></br>
          <br></br>
          <br></br>
        </div>
        <div style={{ 
          marginLeft: isMobile ? '0' : '5vw', 
          width: isMobile ? '100%' : '80vw',
          paddingLeft: isMobile ? '20px' : '0px',
          paddingRight: isMobile ? '20px' : '0px'
          }}>
          <LandingPagePhotos isMobile={isMobile}></LandingPagePhotos>
        </div>
        <div className="lander" style={{ marginLeft: isMobile ? '0px' : '32vw', width: isMobile ? '100%' : '30vw', marginBottom:'40px'}}>
          <JoinWaitlistInputFields 
              Option={Option} 
              isMobile={isMobile} 
            ></JoinWaitlistInputFields>
        </div>
        <br></br>
      </>
    );
  }

  // Input into JSX below...
  return (
    <div className="Home">
      {isAuthenticated ? 
        <>
          <TopFilters/>

          {isMobile ?
            <Row gutter={0}>
              <Col span={24} style={{marginTop: '6px'}}>
                <GoogleMapOpto isMobile={isMobile} loadingData={loadingData} shiftList={allDataForDoctorFindShiftsPage?.shiftData} />
                <RenderShiftList 
                  userData={userData} 
                  isMobile={isMobile}
                  allDataForDoctorFindShiftsPage={allDataForDoctorFindShiftsPage} 
                  onLoginEvent={onLoginEvent}
                  filteredDataForDoctorFindShiftsPage={filteredDataForDoctorFindShiftsPage}
                  numberOfShiftsAvailable={numberOfShiftsAvailable}
                  filterteredListActive={filterteredListActive}
                  />
              </Col>
            </Row>
          : 
            <Row gutter={0}>
              <Col span={8} style={{paddingLeft: '0px' , marginTop: '6px'}}>
                <RenderShiftList 
                  userData={userData} 
                  isMobile={isMobile}
                  allDataForDoctorFindShiftsPage={allDataForDoctorFindShiftsPage}
                  onLoginEvent={onLoginEvent}
                  filteredDataForDoctorFindShiftsPage={filteredDataForDoctorFindShiftsPage}
                  numberOfShiftsAvailable={numberOfShiftsAvailable}
                  filterteredListActive={filterteredListActive}
                  />
              </Col>
              <Col span={16} style={{marginTop: '20px'}}>
                <p style={{fontSize: '13px' , marginTop: '-24px', position: 'absolute', right: '26px' }}>*available shifts within 50 mile radius from you ({userData.zipCode})</p>
                <GoogleMapOpto isMobile={isMobile} loadingData={loadingData} shiftList={allDataForDoctorFindShiftsPage?.shiftData} />
              </Col>
            </Row>
          }

          <GenericModal
            handleCancel={handleCancel}
            modalTitle={"Confirm Apply"}
            isGenericModalOpen={isGenericModalOpen} 
            bodyText={"Are you sure you want to apply to this shift?"}
            showButton='true'
            showCancelButton='true'
            showLoadingSpinner={false}
            showButtonLoading={confirmApplyButtonLoading}
            buttonFunction={applyToShift} 
            buttonText={"Apply"} 
            zIndex={1000}
          ></GenericModal>

          {/* Clinic Information Modal - From Open Shift List */}
          {/* TODO: Finish this.... */}
          <Modal
            title="Clinic Information"
            centered
            open={isClinicInformationModalOpen}
            onOk={closeClinicInformationModal}
            onCancel={closeClinicInformationModal}
            footer={
              <>
                <Button className="secondary-button button-size-default" onClick={closeClinicInformationModal}>
                  Back
                </Button>
                <Button className="primary-button button-size-default" onClick={closeClinicInformationModal}>
                  Okay
                </Button>
              </>
            }
          >
            {/* <DoctorCard_CalendarInfo userData={currentlySelectedDoctorInformation}/> */}
            <ClinicInfoCard_DoctorCalendar clinicData={currentlySelectedShiftCard} />

            {/* TODO: Add clinic information here */}

          </Modal>

        </>
      : 
      renderLandingPage()}
    </div>
  );
}