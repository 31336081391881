import React, { useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { API } from "aws-amplify";
import { onError } from "../lib/errorLib.js";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Modal, Input, Checkbox, Row, Col, Upload, Tag, notification } from 'antd';
import { UploadOutlined, CheckCircleTwoTone, CloseCircleTwoTone, } from '@ant-design/icons';

function EditDoctorProfileModal(props) {

    const { 
        isMobile,
        handleCancel,
        userData,
        isEditDoctorProfileInformationModalOpen,
        SetIsEditDoctorProfileInformationModalOpen,
        setIsWelcomModalOpen,
        onLoginEvent
    } = props

    const [api, contextHolder] = notification.useNotification();
    const { TextArea } = Input;
    const nav = useNavigate();

    const defaultValues = { 
        licenseNumber: '',
        npiNumber: '',
        linkedInProfileUrl: '',
        isGlassesAndContacts: true,
        isMedical: false,
        doctorNotes: '',
    };

    // react-hook-form: //
    // The form control enables react-hook-form to start tracking the state of this form.
    // One control object to be used in all fields on the form to join and track that data from a single object.
    // "react hook form is in charge of the form state"
    const { handleSubmit, control, formState: { errors }, reset } = useForm({ defaultValues });

    const [editDoctorProfileInformationButtonLoading, setEditDoctorProfileInformationButtonLoading] = useState(false);

    const closeModal = () => {
        handleCancel();
        reset(defaultValues);
    };

    const onSubmit = data => {
        setEditDoctorProfileInformationButtonLoading(true);

        // Data is the root object of the form data
        console.log("react-hook-form-data submitted object: ", data);

        // Need to add userType to th data to allow pushing to dynamoDB
        data.userType = userData.userType;
        data.onboardingComplete = 'true';

        updateDoctorUserData(data);

    };

    const updateDoctorUserData = (additionalDoctorData) => {
        console.log("adding additional info to doctor dynamo item.");

        try {
            // PUT to /userData is update
            return API.put("od-api", "/userData", {
              body: additionalDoctorData,
            }).then((data) => {
              console.log("join org response: ", data);
              if(data.statusCode === 200){ // check response to see if succesful or not.
                openNotification('Added Doctor Information Succesfully');
                SetIsEditDoctorProfileInformationModalOpen(false);
                setIsWelcomModalOpen(false);
                setEditDoctorProfileInformationButtonLoading(false);
      
                // the page will not resfresh until we tell it to. Pull updated userData.
                onLoginEvent();

                nav("/");
      
              }else{
                openNotification('Doctor Information Was Not Added Succesfully');
                setEditDoctorProfileInformationButtonLoading(false);
              }
            });
          } catch (e) {
            onError(e);
            console.log("ERROR: ", e);
        }
    }

    // toast notification
    const openNotification = (message) => {
        api.open({
            message: message,
            icon: message === "Doctor Information Was Not Added Succesfully" ? 
            <CloseCircleTwoTone style={{ color: '#108ee9' }} /> 
            : 
            <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
        });
    };

    return (
        <>
            {contextHolder}
            <Modal
                open={isEditDoctorProfileInformationModalOpen}
                onOk={handleSubmit(onSubmit)}
                onCancel={closeModal}
                centered
                footer={ 
                    <>
                        <Button htmlType="submit" className="secondary-button button-size-default" onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button htmlType="submit" loading={editDoctorProfileInformationButtonLoading} className="primary-button button-size-default" onClick={handleSubmit(onSubmit)}>
                            Save
                        </Button>
                    </>
                }
            >
                <form>
                    <h5>Doctor Information and Preferences</h5>
                    <Row gutter={[0, isMobile ? 0 : 24]}>
                        <Col span={24}>
                            <div style={{ marginBottom: '0px', marginTop: '12px' }}>
                                <label>License Number</label>
                                <Controller
                                    name="licenseNumber"
                                    control={control}
                                    rules={{ required: 'Please input your license number!' }}  // react-hook-form validation rule
                                    render={({ field }) => (
                                        <Input {...field} placeholder="License number" maxLength={60} />
                                    )}
                                />
                                {/* Error message with reserved space */}
                                <div
                                    style={{
                                        height: '16px',  // Reserve height for the error message
                                        fontSize: '12px',
                                        textAlign: 'right',
                                        color: 'red',
                                        visibility: errors.licenseNumber ? 'visible' : 'hidden',
                                    }}
                                >
                                    {errors.licenseNumber?.message}
                                </div>
                            </div>

                            <div style={{ marginBottom: '0px' }}>
                                <label>NPI Number</label>
                                <Controller
                                    name="npiNumber"
                                    control={control}
                                    rules={{ required: 'Please input your NPI number!' }}
                                    render={({ field }) => (
                                        <Input {...field} placeholder="NPI number" maxLength={60} />
                                    )}
                                />
                                <div
                                    style={{
                                        height: '16px',
                                        fontSize: '12px',
                                        textAlign: 'right',
                                        color: 'red',
                                        visibility: errors.npiNumber ? 'visible' : 'hidden',
                                    }}
                                >
                                    {errors.npiNumber?.message}
                                </div>
                            </div>

                            <div style={{ marginBottom: '8px' }}>
                                <label>LinkedIn Profile Url (optional)</label>
                                <Controller
                                    name="linkedInProfileUrl"
                                    control={control}
                                    rules={{
                                        pattern: {
                                            value: /^(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w\.-]*)*\/?$/,
                                            message: 'Please enter a valid LinkedIn profile URL',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <Input {...field} placeholder="https://www.linkedin.com/in/<your profile>" maxLength={100} />
                                    )}
                                />
                                <div
                                    style={{
                                        height: '16px',
                                        fontSize: '12px',
                                        textAlign: 'right',
                                        color: 'red',
                                        visibility: errors.linkedInProfileUrl ? 'visible' : 'hidden',
                                    }}
                                >
                                    {errors.linkedInProfileUrl?.message}
                                </div>
                            </div>

                            <div>
                                <Row>
                                    <Col span={9}>
                                        <Upload>
                                            <Button disabled icon={<UploadOutlined />}>Upload Resume</Button>
                                        </Upload>
                                    </Col>
                                    <Col span={14}>
                                        <Tag color="blue" style={{ marginLeft: isMobile ? '32px' : '0px' }}>Coming Soon</Tag>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <div style={{ marginTop: isMobile ? '16px' : '-10px' }}>
                        <p style={{fontSize: '14px'}}>
                            Exam type:
                        </p>

                        <Row gutter={16} style={{ marginTop: '-10px' }}>
                            <Col>
                                <div>
                                    <Controller
                                        name="isGlassesAndContacts"
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox {...field} checked={field.value}>
                                                Routine
                                            </Checkbox>
                                        )}
                                    />
                                </div>
                            </Col>

                            <Col>
                                <div>
                                    <Controller
                                        name="isMedical"
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox {...field} checked={field.value}>
                                                Medical
                                            </Checkbox>
                                        )}
                                    />
                                </div>
                            </Col>
                        </Row>
                        </div>

                        <Col span={24}>
                            <div style={{ marginBottom: '12px', marginTop: isMobile ? '16px' : '-8px' }}>
                                <label>Notes about me (optional)</label>
                                <Controller
                                    name="doctorNotes"
                                    control={control}
                                    render={({ field }) => (
                                        <TextArea {...field} rows={2} placeholder="add notes" maxLength={600} />
                                    )}
                                />
                            </div>
                        </Col>
                    </Row>
                </form>
            </Modal>
        </>
    );
}

export default EditDoctorProfileModal;



// Notes
// Controlled forms vs uncontrolled forms. its re-rendering
// controlled, is an input that is referencing a useState variable that is constantly rendering into it.
// useRef is allowing you to refernce the actual HTML, without value={}, to update the value that way, instead of directly calling the value={}
// read up on refs 

// Learn about context in React
// a parent component that holds state for all children, then dont need to manage props between many components.
// https://react.dev/learn/passing-data-deeply-with-context
// https://react.dev/learn/scaling-up-with-reducer-and-context
// Combining context and useReducer can be really powerful
// then you can right unit tests to check your state.