import React, { useEffect, useRef, useState } from 'react';
import "./AddressAutoComplete.css";

export default function AddressAutoComplete (props){
  const { 
    setAddress,
    previousAddress
   } = props

  const [isGoogleReady, setGoogleReady] = useState(window.google ? true : false);
  const [inputValue, setInputValue] = useState(previousAddress || '');
  const inputRef = useRef(null);

  // Check for google object availability
  useEffect(() => {
    const checkGoogle = setInterval(() => {
      if (window.google && window.google.maps.places) {
        setGoogleReady(true);
        clearInterval(checkGoogle);
      }
    }, 100);  // Check every 100 milliseconds

    return () => clearInterval(checkGoogle);
  }, []);

  // Initialize Google Autocomplete once Google is ready
  useEffect(() => {
    if (isGoogleReady && inputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ['address'],
        componentRestrictions: { country: 'us' },
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.formatted_address) {
          console.log('Address selected:', place.formatted_address);
          setAddress(place.formatted_address);
          setInputValue(place.formatted_address);  // Update local state with the selected address
        }
      });

      // Clean up Google Autocomplete event listeners on unmount
      return () => {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      };
    }
  }, [isGoogleReady, setAddress]);

  // Update inputValue if previousAddress changes
  useEffect(() => {
    if (previousAddress) {
      setInputValue(previousAddress);
    }
  }, [previousAddress]);

  return <input 
    ref={inputRef} 
    type="text" 
    placeholder="address" 
    value={inputValue} // Bind inputValue to the input field
    onChange={(e) => setInputValue(e.target.value)} // Update inputValue on user typing
    style={{width: "100%", height: '33px', marginTop: '-6px', fontSize: '14px'}}
  />;
};