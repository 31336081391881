import React, { useState, useEffect, useRef, useCallback } from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import { AppContext } from "./lib/contextLib";
import { onError } from "./lib/errorLib";
import Routes from "./Routes";
import Logo from "./components/Logo";
import Footer from "./components/Footer";
import { API } from "aws-amplify";
import "./App.css";

// onboarding modal logic
import WelcomeModal from "./utilityComponents/WelcomeModal";
import JoinOrgModal from "./utilityComponents/JoinOrgModal";
import CreateClinicModal from "./utilityComponents/CreateClinicModal";
import CreateOrEditShiftModal from "./utilityComponents/CreateOrEditShiftModal";
import EditDoctorProfileModal from "./utilityComponents/EditDoctorProfileModal";
import NotificationContent from "./utilityComponents/NotificationContent";


// Generic Modal Utility
import GenericModal from "./utilityComponents/GenericModal";

import { MailOutlined, 
         TeamOutlined, 
         CalendarOutlined,
         UserOutlined,
         MenuOutlined,
         BellOutlined,
         LoginOutlined, 
         LogoutOutlined,
         SearchOutlined,
         LineChartOutlined
} from '@ant-design/icons';

import { Button, Layout, Menu, Drawer, Grid, Tag, Badge, Spin, List, Row, Col, Popover, Typography} from 'antd';

const { Text } = Typography;

  // ------------------------------ //
  // ------------------------------ //

  /////// Mission Critical: ///////

  // BUG, when editting clinic data, need to also update embedded clinic data in all associated shifts.
  // This wil fix when a shift updates info, docotors will see the updated info in their calendar, and in the open shift list.

  //   -  Cost Optimize: Setup a timer, to know how long it has bee since the user's last interaction on the page.. if more than 3 minutes
  // Stop pulling data until the user interacts with the page again. Check mouse position on the page,
  // can we check if the page is focused in the browser? ask chat gpt.. how can we tell if the user is interacting with our page?

  /////// Small pre-launch items //////

  //   -  After Create Clinic, tell user "go to your clinic calendar to add shifts"
  //   -  Clear console.logs all over, on button clicks etc.
  //   -  Bug if clinic updated clinic data, it does not pull into doctor calendar clinic data information panel, because it is pulling from the shift data embedded object which is out of date now...
          // need to also loop through fhist data, and if they are associated to that clinic, update that clinic data also from lambda
  //   -  if selecting a date range, pause api polling.. or look to use components instead of functions and see if tht fixes this....
  //   -  Manual Refresh buttons

  // Post Launch // (8+ hours)
  //   -  Finish 'un-accept' and 'cancel shift' flow.
  //   -  Make the "Edit" Modal button (and modal itself) on accepted doctor, to "un-accept" a doctor. (2 hours)
          // Coming Soon.. reach out to doctor to cancel.
          // Should only be able to do this 24 hours before a shift.
  //   -  Cancel Shift Button on doctor calendar pending/scheduled shift modal (with confirmation modal) (4 hours)
          // Coming Soon.. reach out to clinic to cancel.
          // Need new API call. need to take out data on both shiftData and UserData dynamo db items
          // Should only be able to do this 24 hours before a shift. Add to footer.
  //   -  Add Google Analytics (2 hours)
  //   -  Additional input validation.. make sure zip code is specific length.. email and phone number are formatted correctly, etc
  //   -  Refactor in Home.js - open shift card data in filtered list of shifts is not getting dynamically re-pulled.. that list is only populated
          // when the date range is picked... then the data is no longer re-pulled into that list... fix this.
  //   -  link to zocdoc?? do opto have zocdoc

 /////// High Priority: ///////
  //   -  Check if Doctor has applied to two shifts on the same day... open modal to tell them to cancel other shifts.
  //   -  Check when new clinicTeamMember creates an account if any other colleagues of theirs has already joined and created an org.
          // Automatically join them to the org.
  //   -  Add Currently Selected State on calendar button in left naivgation panel on initial page refresh/load
  //   -  Only Refresh Find Shifts List if the data has been changed... This will fix the scrolling up on refresh bug. (1 hour)
  //   -  Add logic to mark a notification as "read", new key value in acceptedDoctor and interestedDoctor Objects in dynamoDB..
          // use this first hard coded filter, then expose additional filtering down stream.
  //   -  Add "Add Clinic" Button in clinic calendar
  //   -  Edit Marketing Page, add extra buttons and modals
  //   -  Go through and take out majority of console logs. even on button clicks, ie. create new shift.
  //   -  Clean up.. Take out notes logic... Take out Lambda functions that we are not using.
  //   -  Add dynamodb table config for IsLambdaLoggingOn. Then have api call first thing in all Lambda functions. Should be negligable latency.
          // Then have ability to externally toggle on and off logging within Lambda functions without needing to re-deploy infra.
  //   -  in Clinic Information Modal in Doctor calendar, click on address, to open map in google maps in new tab.
  //   -  Delete Org Logic
  //   -  Delete Clinic logic
        // Delete all associated shifts... need new API call.

  //   -  Ensure no duplicate clinics created //
  //   -  clinic creation check... when a company creates aclinic we can check and see if there is another clinic created with that same
  //   -  clinic email address, tell them if there is already a clinic that exists.



  /////// Mid Priority: ///////
  //   -  REFACTOR and pull out utilities.. IMPORTANT
  //   -  Handle Doctor Shift "Scheduled Elsewhere":
          // May need to also add an AcceptedShifts Key in dynamoDB for doctors... already have interestedShifts in there.
          // We could then use this to keep track if a doctor applies to multiple shifts on the same day and then gets "scheduled" for a one,
          // On the doctor applicants lists of the shifts he did not apply for, it should show "Scheduled Elsewhere" or something..
          // But then need to manage when canceled or that changes, need to change it in BOTH the shiftData AND userData locations...

  //   -  Send email to doctor when scheduled shift is upcoming in 24 hours from now.. Need eventBridge scheduled event?
  //   -  Organize Open shifts to show most recent at the top.
  //   -  Organize Notification lists to show most recent at the top.
  //   -  .ics creation and download to import calendar events to outlook or google calendar
  //   -  Look to add API Gateway caching, to decrease calls to dynamodb with rest api polling. 

  //////  Low Priority: ///////
  //   -  if individual doctor has opted into getting notifications/emails when new shifts are posted in their area, they get a notification/email.
  //   -  add logic around shifts in the past, or "completed" shifts.
  //   -  add information circle i button to join org and create org buttons and all over the place. make sure to explain very clearly what is going on.
  //   -  Add more info pop overs to explain things in onboarding forms/modals.
  //   -  Add Google auth
  //   -  Figure out invite colleagues logic/flow
  //   -  Add corner button - https://ant.design/components/float-button#:~:text=Open%20menu%20mode%20with%20trigger%2C%20which%20could%20be%20hover%20or%20click.
  //   -  Improve input validation
  //   -  After Log out clear all userData and other data. This will stop recurring API calls after logout.

  // ------------------------------ //
  // ------------------------------ //

function App() {
  const nav = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  // Data from DynamoDB Tables
  const [userData, setUserData] = useState({});
  const [orgData, setOrgData] = useState({});
  const [clinicData, setClinicData] = useState([]);
  const [allDataForDoctorFindShiftsPage, setAllDataForDoctorFindShiftsPage] = useState({});

  // Notification system
  const [shiftsWithInterestedDoctors, setShiftsWithInterestedDoctors] = useState({}); // For clinicTeamMembers
  const [interestedShifts, setInterestedShifts] = useState({}); // For individualDoctors
  const [notificationObject, setNotificationObject] = useState([]);
  const [notificationPopoverVisible, setNotificationPopoverVisible] = useState(false);
  const [isMobileNotificationsModalOpen, setisMobileNotificationsModalOpen] = useState(false);

  // Onboarding Modal Logic
  const [isWelcomeModalOpen, setIsWelcomModalOpen] = useState(false); // onboarding modal
  const [isJoinOrgModalOpen, setIsJoinOrgModalOpen] = useState(false); // onboarding modal
  const [isCreateClinicModalOpen, setIsCreateClinicModalOpen] = useState(false); // onboarding modal
  const [createOrgSequence, setCreateOrgSequence] = useState(false); // if false, defaults to JoinOrgSequence.

  // Finish Docotor Onboarding
  const [isEditDoctorProfileInformationModalOpen, SetIsEditDoctorProfileInformationModalOpen] = useState(false);
  
  // Calendar Helper
  const [isCreateOrEditShiftModalOpen, setIsCreateOrEditShiftModalOpen] = useState(false);
  const [currentlySelectedShiftToEdit, setSelectedShiftToEdit] = useState({});
  const [selectedCalendarDate, setSelectedCalendarDate] = useState("");
  const [doctorFormattedInterestedShiftDataForCalendar, setDoctorFormattedInterestedShiftDataForCalendar] = useState({});
  const [currentlySelectedClinicCalendar, setCurrentlySelectedClinicCalendar] = useState();
  const [clinicCalendarList, setClinicCalendarList] = useState([]); // Calendar Clinic List

  // For Generic Modal Utility
  const [isGenericModalOpen, setIsGenericModalOpen] = useState(false); // generic modal - used in add clinic process.
  const [genericModalTitle, setGenericModalTitle] = useState(""); 
  const [genericModalBodyText, setGenericModalBodyText] = useState("");
  const [genericModalButtonFunction, setGenericModalButtonFunction] = useState(() => {return () => console.log("Default action executed"); });
  const [genericModalButtonText, setGenericModalButtonText] = useState("");
  const [showGenericModalShowSpinner, setShowGenericModalShowSpinner] = useState("false");

  // UI Layout Helpers 
  const [visibleDrawer, setVisibleDrawer] = useState(false); // menu drawer
  const { Header, Content, Sider } = Layout;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isMobile = !screens.md; 
  const [loadingData, setLoadingData] = useState(false);
  const [currentPage, setCurrentPage] = useState("");

  ////////////////////
  // Data Refresh Time
  const [dataRefreshTime, setDataRefreshTime] = useState(60000); // milliseconds each refresh // in prod have this slow like 30 seconds..
  ////////////////////

  useEffect(() => {
    onLoad();
    // console.log("useEffect working from App.js");
  }, []);

  const location = useLocation();
  // Update the current page whenever the route changes
  useEffect(() => {
    let currentPage_useLocation = location.pathname;
    if(currentPage_useLocation.startsWith('/')){
      currentPage_useLocation = currentPage_useLocation.substring(1).trim();
    }

    setCurrentPage(currentPage_useLocation); // Use location.pathname to set the current route
    // console.log("currentPage_useLocation react hook: ", currentPage_useLocation);
  }, [location]);

  // Using this to clear session Storage for calendar 
  // Reset sessionStorage value when the page reloads // enable rest api polling
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Clear the flag when the page is about to be refreshed
      sessionStorage.removeItem("initialCalendarLoaded");
      sessionStorage.removeItem("currentlySelectedClinicCalendar");
      sessionStorage.removeItem("currentlyEdittingInformation");
    };

    // Add the event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);


  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      await onLoginEvent();

    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function onLoginEvent() {

    // Reminder:
    // After Sign Up in SignUp.js, it waits for 1 second before triggering this to make sure that when we pull the user data, it is populated.

    // Wait for things to load into frontend.
    setLoadingData(true);
    await delay(300);

    // API Call to get userData
    const userDataReponse = await getUserData();
    const userDataParsed = JSON.parse(userDataReponse.body);
    console.log("userData: ", userDataParsed.userData);
    setUserData(userDataParsed.userData);

    // TODO: In doctor onboarding flow... Don't continue if userData is undefined..
    const user = await Auth.currentAuthenticatedUser();
    // console.log("user: ", user);

    // confirm user is authenticated
    if(user !== undefined && userDataParsed.userData !== undefined){
      // Curate onboarding Sequence per user.
      // Conditwionally turn on correct onboarding modal based on current user onboarding progress.
      if(userDataParsed.userData.userType === 'clinicTeamMember'){
        // user has either joined or created an org.
        if(userDataParsed.userData.orgId != undefined){

          // check if their org has any associated clinics.
          // need api call here to check the org database.
          const orgParams = {
              orgId: userDataParsed.userData.orgId, 
          }
          const orgDataResult = await getOrgData(orgParams);
          console.log("orgData: ", orgDataResult.orgData);
          setOrgData(orgDataResult.orgData);

          // TODO:
          // If their org does have associated clinics, set this user's data to onboardingComplete=True

          // Now get Clinic Data and Shift Data
          if(orgDataResult?.orgData?.clinics != undefined){

            await getClinicDataAndCheckShiftsForInterestedDoctors(userDataParsed);
            setLoadingData(false);

            // REST API POLLING //
            setInterval( async () => {
              // console.log("userDataParsed: ", userDataParsed);
              await getClinicDataAndCheckShiftsForInterestedDoctors(userDataParsed);

            }, dataRefreshTime); 

          }else{
            // If you are part of an org, but have no associated clinics
            OpenModal_Generic(
              "Add a Clinic",
              "It looks like your Organization does not have any associated clinics yet. Add one or more clinics to start posting open shifts.",
              OpenModal_CreateAClinic,
              "Add Clinic",
              false
            );

            setLoadingData(false);
          }

        }else{
          setLoadingData(false);

          // TODO: Need to complete onboardingComplete, and set that to true for both doctors and clinicTeamMembers
          OpenModal_InitialOnboard();
        }
      }else{
        // individual doctor 

        // Onboarding modal logic - TODO - Break out into seperate component...
        if(userDataParsed.userData != undefined && userDataParsed.userData.onboardingComplete){
          console.log("user has completed onboarding.");
          await getALLClinicDataAndCheckShiftsForInterestedShifts(userDataParsed);

          // Dont show loading in the doctor or clinic until they actually have data saved.
          // We don't want to flash the loading 
          setLoadingData(false);

          // REST API POLLING //
          setInterval( async () => {
            // console.log("userDataParsed: ", userDataParsed);
            await getALLClinicDataAndCheckShiftsForInterestedShifts(userDataParsed);
          }, dataRefreshTime); 

        }else{
          setLoadingData(false);
          OpenModal_InitialOnboard();
        }

      }
    }else{
      console.log("not authentcated or userData is undefined.");

    }

  };


  // NOTIFICATIONS //
  const setNotificationPopoverVisibleTrigger = () => {
    if(notificationObject?.length > 0){
      setNotificationPopoverVisible(!notificationPopoverVisible);
    }
  }

  // dont need to pass in userId, lambda gets user id from 
  // event.requestContext.authorizer.iam.cognitoIdentity.identityId
  function getUserData() {
    try {
      return API.post("od-api", "/getUserData", {
        body: {userId: ""},
      }).then((data) => {

        console.log("get user data response: ", data);
        // do things
        return data;

      });
    } catch (e) {
      onError(e);
      console.log("ERROR: ", e);
    }
  }

  //////////////
  //////////////
  // Recurring Sequential Execution For interestedDoctor Frontend Notification Logic
  //////////////
  //////////////

  // Only getting this specific Organization's clinic and shift data.
  async function getClinicDataAndCheckShiftsForInterestedDoctors(userDataParsed){

    // Check if all modals are closed.. if so, not editting information
    // Set currentlyEdittingInformation(false);
    const isModalOpen = () => {
      // Look for the '.ant-modal-mask' element which appears when a modal is open
      const modalOverlay = document.querySelector('.ant-modal-mask');
    
      // If the overlay is present, assume the modal is open
      return modalOverlay !== null;
    };

    if (isModalOpen()) {
      // console.log('The modal is currently open!');
    } else {
      // console.log('The modal is closed.');
      sessionStorage.setItem("currentlyEdittingInformation", false); // enable rest api polling
    }

    // Only re-pull data if not currently editting information, ie shift data, proflie data, clinic data, etc.
    // So that we don't want to "re-pull and clear", use sessionStorage to hold state data on this...
    const currentlyEdittingInformation = sessionStorage.getItem("currentlyEdittingInformation");
    // console.log("currentlyEdittingInformation: ", currentlyEdittingInformation);

    if(currentlyEdittingInformation === null || currentlyEdittingInformation === 'false'){
      console.log("RE-PULLING DATA AUTOMATICALLY & SEQUENTIALLY - CLINIC SIDE");

      const clinicParams = {
        orgId: userDataParsed.userData.orgId,
      };
      const clinicData = await getClinicAndShiftData(clinicParams);
      
      if(clinicData !== undefined){
  
        console.log("clinicData: ", clinicData);
        setClinicData(clinicData?.clinicData);
        
        // ONLY DO THIS ON FIRST PAGE LOAD... WE DONT WANT TO ALWAYS DEFAULT TO THE FIRST CALENDAR ON EACH PAGE LOAD...
        // if no currenty selected clinic, use default first clinic
        // Trying to fix defaulting back to first calendar every time we recieve new data.. conditionally pass which new calendar data to show based on which calendar is selected.
        // persist accross component re-renders.. using sessionStorage
        const savedCalendarLoaded = sessionStorage.getItem("initialCalendarLoaded");
        // console.log("savedCalendarLoaded: ", savedCalendarLoaded);
  
        // console.log("initialCalendarLoaded: ", initialCalendarLoaded);
        if(currentlySelectedClinicCalendar === undefined && savedCalendarLoaded === null){
        // if(currentlySelectedClinicCalendar === undefined){
          setCurrentlySelectedClinicCalendar(clinicData?.clinicData[0]); // default first clinic to inital selection
          sessionStorage.setItem("currentlySelectedClinicCalendar", clinicData?.clinicData[0]?.clinicId); // save which calendar you are currently on to sessionStorage..
          // console.log("default currectlySelectedClinicCalendar: ", clinicData.clinicData[0]);
          sessionStorage.setItem("initialCalendarLoaded", true);
        }else{
          // find out which clinicCalendar you were on previously and populate that one on new data load..
          const currentlySelectedClinicCalendarFromSessionStorage = sessionStorage.getItem("currentlySelectedClinicCalendar");
          // console.log("currentlySelectedClinicCalendarFromSessionStorage: ", currentlySelectedClinicCalendarFromSessionStorage);
  
          // console.log("clinicData.clinicData.length: ", clinicData.clinicData.length);
          for(var i=0; i< clinicData?.clinicData.length; i++){
            // console.log("checking which calendar is currently selected: ", clinicData.clinicData[i]?.clinicId + " vs. " + currentlySelectedClinicCalendarFromSessionStorage);
            if(clinicData.clinicData[i]?.clinicId === currentlySelectedClinicCalendarFromSessionStorage){
              // console.log("should reset calendar data with new data, ", clinicData.clinicData[i]);
              setCurrentlySelectedClinicCalendar(clinicData.clinicData[i]);
            }
          }
          
        }
    
        // Here check for "interestedDoctors" and populate a new state object.
        // console.log("Now check the shifts for interestedDoctors, and send notification in frontend.");
        let shiftsWithInterestedDoctors_ShiftIdList = []; // use this to check against to make sure there are not duplicates.
        let shiftsWithInterestedDoctors_ObjectList = [];
    
        
        for(var i=0; i<clinicData.clinicData.length; i++){
    
          // loop through and grab the shifts that doctors have applied to.
          if(clinicData?.clinicData[i].shiftData != undefined){
            for(var j=0; j<clinicData.clinicData[i].shiftData.length; j++){
              if(clinicData.clinicData[i].shiftData[j].interestedDoctors != undefined){
                for(var k=0; k<clinicData.clinicData[i].shiftData[j].interestedDoctors.length; k++){
                  if(!shiftsWithInterestedDoctors_ShiftIdList.includes(clinicData.clinicData[i].shiftData[j].shiftId)){
                    shiftsWithInterestedDoctors_ShiftIdList.push(clinicData.clinicData[i].shiftData[j].shiftId);
                    shiftsWithInterestedDoctors_ObjectList.push(clinicData.clinicData[i].shiftData[j]);
                    // console.log("Shift containing an interestedDoctor: ", clinicData.clinicData[i].shiftData[j]);
                  }
                };
              }
            };
          }
    
          // check clinics and find the currently selected clinic calendar to reset the data.
        };
    
        // console.log("shiftsWithInterestedDoctors_ShiftIdList: ", shiftsWithInterestedDoctors_ShiftIdList);
        // console.log("FOR NOTIFICATION SYSTEM - CLINIC SIDE - Shifts With Interested Doctors - check if doctors have applied to your open shift: ", shiftsWithInterestedDoctors_ObjectList);
        setShiftsWithInterestedDoctors(shiftsWithInterestedDoctors_ObjectList);
    
        // SETUP NOTIFICATION OBJECT FOR CLINICS
        reformatClinicShiftObjectsForNotificationPanel(shiftsWithInterestedDoctors_ObjectList, userDataParsed);

        // Need to check which clinics have shifts that have interestedDoctors, but are not "Scheduled".
        const clinicsWithShiftsWithInterestedDoctorsButNotScheduled = [];
        // create clinicsWithShiftsWithInterestedDoctorsButNotScheduled array
        for(var i=0; i<clinicData?.clinicData.length; i++) {
          for (var j=0; j<clinicData?.clinicData[i]?.shiftData?.length; j++){
            if(clinicData?.clinicData[i]?.shiftData[j]?.acceptedDoctor === undefined
              && clinicData?.clinicData[i]?.shiftData[j]?.interestedDoctors?.length > 0
            ){
              // this shift has interestedDoctors but none have been accepted yet, show notification.
              // add to list clinicsWithShiftsWithInterestedDoctorsButNotScheduled
              clinicsWithShiftsWithInterestedDoctorsButNotScheduled.push(clinicData?.clinicData[i].clinicName);
              // clinicData?.clinicData[i].shiftData[j].hasInterestedDoctorsButNotScheduled = true;
            }
          }
        }
        // console.log("clinicsWithShiftsWithInterestedDoctorsButNotScheduled: ", clinicsWithShiftsWithInterestedDoctorsButNotScheduled);

        // TODO: make the first item have class "ant-menu-item ant-menu-item-selected" to show blue and selected in the menu...
        // Set list of calendars in the left main menu.
        const opticalItemsFormat = clinicData?.clinicData.map((clinic, index) =>
          getItem(
            clinicsWithShiftsWithInterestedDoctorsButNotScheduled.includes(clinic.clinicName) ? 
              <Badge color="#F759AB"  dot  offset={[16, 16]}>
                {clinic.clinicName} 
              </Badge>
            : 
            clinic.clinicName
            , 
            clinic.clinicId)
        );
        setClinicCalendarList(opticalItemsFormat);
  
      }

      return clinicData;

    }else{
      console.log("not re-pulling data because currently editting information.")
    }

  }

  // Getting ALL Organization's clinic and shift data.
  // Get list of "InterestedShifts" to create ui panel, also get notifications of any new shifts in your area.
  async function getALLClinicDataAndCheckShiftsForInterestedShifts(userDataParsed){

    console.log("RE-PULLING DATA AUTOMATICALLY & SEQUENTIALLY - DOCTOR SIDE");

    const clinicParams = {
      lat: userDataParsed.userData.lat,
      lng: userDataParsed.userData.lng
    };
    const AllData = await getALLClinicAndShiftData(clinicParams);

    // Check to see if identical to last pull before setting the data into state and refreshing the app
    // ONLY setAllDataForDoctorFindShiftsPage THIS IF IT HAS CHANGED SINCE THE LAST RE_PULL
    console.log("AllData: ", AllData);
    setAllDataForDoctorFindShiftsPage(AllData);

    // Here check for "interestedShifts" and populate a new state object.
    // console.log("Now check the shifts for interestedShifts, and set up object to view in frontend.");
    let InterestedShifts_ShiftIdList = []; // use this to check against to make sure there are not duplicates.
    let InterestedShifts_ObjectList = [];
    // console.log("userDataParsed.userData.userId: ", userDataParsed.userData.userId);

    // loop through and grab the shifts that the doctor has applied to.
    for(var i=0; i<AllData.shiftData.length; i++){
      if(AllData.shiftData[i].interestedDoctors != undefined){
        for(var j=0; j<AllData.shiftData[i].interestedDoctors.length; j++){
          // console.log("AllData.shiftData[i].interestedDoctors[j]: ", AllData.shiftData[i].interestedDoctors[j]);
          // only grab shifts of which you are one of the interested doctors.
          if(AllData.shiftData[i].interestedDoctors[j].doctorId == userDataParsed.userData.userId){ 
            if(!InterestedShifts_ShiftIdList.includes(AllData.shiftData[i].shiftId)){
              InterestedShifts_ShiftIdList.push(AllData.shiftData[i].shiftId);
              InterestedShifts_ObjectList.push(AllData.shiftData[i]);
            }
          }
        };

      }
    };

    // console.log("shiftsWithInterestedDoctors_ShiftIdList: ", shiftsWithInterestedDoctors_ShiftIdList);
    // console.log("FOR NOTIFICATION SYSTEM - DOCTOR SIDE - Interested Shifts - check if you have been accepted or someone else has been: ", InterestedShifts_ObjectList);
    setInterestedShifts(InterestedShifts_ObjectList);

    if(InterestedShifts_ObjectList != undefined){
      // Change "SCHEDULED" to "NOT ACCEPTED" if another doctor was accepted..

      // Format the shift data for the calendar, and add new key
      const formattedInterestedShiftData = InterestedShifts_ObjectList.map(event => ({
        title: event.shiftId,
        date: event.shiftDate,
        className: 'custom-event',
        extendedProps: {
          status: checkIfDoctorWasAcceptedOrNot(event, userDataParsed), // Function to check if "NOT ACCEPTED"
          doctor: event.clinicData.clinicName, // using clinic address as second row for doctor modal
          shiftData: event // return the entire shift object in the extendedProps, to access in the calendar.
        }
      }));

      // Need to show "SCHEDULED" on calendar of accepted doctor and "NOT ACCEPTED" on calendar of doctors who are not accepted.
      function checkIfDoctorWasAcceptedOrNot(event, userDataParsed){

        let status = "";
        if(event.shiftState.toUpperCase() === "SCHEDULED"){
          if(event.acceptedDoctor?.doctorId === userDataParsed.userData.userId){
            status = event.shiftState.toUpperCase()
          }else{
            status = "NOT ACCEPTED"
          }
        }else{
          status = event.shiftState.toUpperCase()
        }
        return status
      }

      // console.log("formattedInterestedShiftData: ", formattedInterestedShiftData);
      setDoctorFormattedInterestedShiftDataForCalendar(formattedInterestedShiftData); // adding this data directly into userData to access from within "My Calendar"

      // SETUP NOTIFICATION OBJECT FOR DOCTORS
      reformatDoctorShiftObjectsForNotificationPanel(InterestedShifts_ObjectList, userDataParsed);

    }
  }

  function reformatDoctorShiftObjectsForNotificationPanel(InterestedShifts_ObjectList, userDataParsed){

    // TODO: Only take the most current 20 timestamped events in the list.
    // Doctor side wants the 20 most recent "clinic accepted" notification events.
    // Clinic side wants the 20 most recent "doctor applied" notification events.
    // also need to re-order them to have most current at the top.
    // Create Array of shifts that have accepted this doctor..
    let shiftsThatHaveAcceptedThisDoctor = [];
    for(var i=0; i<InterestedShifts_ObjectList.length; i++){
      if(InterestedShifts_ObjectList[i].acceptedDoctor !== undefined){
        // console.log("found shift that a has an accepted doctor, add this shift to the notification array: ", InterestedShifts_ObjectList[i]);
        shiftsThatHaveAcceptedThisDoctor.push(InterestedShifts_ObjectList[i]);
      }
    }

    // console.log("shiftsThatHaveAcceptedThisDoctor: ", shiftsThatHaveAcceptedThisDoctor);
    if(shiftsThatHaveAcceptedThisDoctor.length > 0){
      const formattedShiftDataForNotifications = shiftsThatHaveAcceptedThisDoctor.map((event, index) => ({
        id: index + 1,
        text: setTextForDoctorNotification(event, userDataParsed),
        time: formatRelativeDate(event.acceptedDoctor.timestamp),
        status: 'unread'
      }));
      // console.log("setting object: formattedShiftDataForNotifications ", formattedShiftDataForNotifications);
      setNotificationObject(formattedShiftDataForNotifications);
    }else{
      setNotificationObject({});
      // console.log("setting notification object empty");
    }

    function setTextForDoctorNotification(event, userDataParsed){
      // console.log("event inside setTextForDoctorNotification: ", event);
      let text = "";

      // console.log("event.acceptedDoctor?.doctorId: " + event.acceptedDoctor?.doctorId + " vs. userData.userId: " + userDataParsed.userData.userId);
      if(event.acceptedDoctor?.doctorId === userDataParsed.userData.userId){
        text = "Accepted to " + event.clinicData.clinicName + " - " + formatDate(event.shiftDate)
      }else{
        text = "Not Accepted to " + event.clinicData.clinicName + " - " + formatDate(event.shiftDate)
      }

      return text
    }
  }

  function reformatClinicShiftObjectsForNotificationPanel(shiftsWithInterestedDoctors_ObjectList, userDataParsed){

    // TODO: Only take the most current 20 timestamped events in the list.
    // Doctor side wants the 20 most recent "clinic accepted" notification events.
    // Clinic side wants the 20 most recent "doctor applied" notification events.

    // console.log("shiftsWithInterestedDoctors_ObjectList: ", shiftsWithInterestedDoctors_ObjectList);

    // create formatted object for notifications panel on clinic side from shiftsWithInterestedDoctors_ObjectList object
    if(shiftsWithInterestedDoctors_ObjectList?.length > 0){
      let formattedShiftDataForNotifications = [];
      let index = 0;
      for(var i=0; i< shiftsWithInterestedDoctors_ObjectList?.length; i++){
        for(var j=0; j<shiftsWithInterestedDoctors_ObjectList[i]?.interestedDoctors?.length; j++){
          const formattedShiftDataForNotifications_specificShift = {
            id: index + 1,
            text: "Dr. " + shiftsWithInterestedDoctors_ObjectList[i]?.interestedDoctors[j]?.doctorName + " has applied to " + shiftsWithInterestedDoctors_ObjectList[i]?.clinicData?.clinicName + " - " + formatDate(shiftsWithInterestedDoctors_ObjectList[i].shiftDate),
            time: formatRelativeDate(shiftsWithInterestedDoctors_ObjectList[i]?.interestedDoctors[j]?.timestamp),
            status: 'unread'
          }
          formattedShiftDataForNotifications.push(formattedShiftDataForNotifications_specificShift);
        }
      }

      // console.log("formattedShiftDataForNotifications: ", formattedShiftDataForNotifications);
      setNotificationObject(formattedShiftDataForNotifications);

    }
  }

  // REFACTOR - PULL OUT UTILITIES
  // Format Data Times for Notification Panel
  function formatDate(dateString) {
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day); // Month is 0-based in JavaScript
  
    // Options for formatting the date
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
  
    // Format the date according to the provided options
    return date.toLocaleDateString('en-US', options).replace(',', ',');
  }

  // Format Data Times for Notification Panel
  function formatRelativeDate(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds
  
    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
  
    if (now - date > oneWeekInMillis) {
      // Format: MM/DD/YYYY h:mm AM/PM
      const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return date.toLocaleDateString('en-US', dateOptions) + ' ' + date.toLocaleTimeString('en-US', timeOptions);
    } else {
      // Format: Day of the Week, h:mm AM/PM
      const dayOptions = { weekday: 'long' };
      return date.toLocaleDateString('en-US', dayOptions) + ', ' + date.toLocaleTimeString('en-US', timeOptions);
    }
  }

  //////////////
  //////////////
  //////////////
  //////////////

  async function rePullClinicData(clinicId){

    console.log("RE-PULLING DATA INTENTIONALLY");

    console.log("rePullClinicData - userData.orgId: ", userData.orgId);
    const clinicParams = {
      orgId: userData.orgId,
    };

    const clinicDataResponse = await getClinicAndShiftData(clinicParams);
    console.log("rePullClinicData - clinicData: ", clinicDataResponse);
    setClinicData(clinicDataResponse.clinicData);

    // re-populate selectedShiftToEdit after Accepting a Doctor, rePull Clinic and Shift data, and repopulate currentlySelectedShiftToEdit
    // console.log("rePullClinicData - currentlySelectedShiftToEdit: ", currentlySelectedShiftToEdit);
    if(currentlySelectedShiftToEdit != undefined){
      // loop through newly pulled data to find the same shift that is the currentlySelectedShiftToEdit.
      for(var i=0; i<clinicDataResponse?.clinicData.length; i++){
        // console.log("clinicDataResponse.clinicData[i]: ", clinicDataResponse.clinicData[i]);

        // update selected shift data to show newly accepted doctor in docotr applicant list
        if(clinicDataResponse?.clinicData[i].shiftData.length > 0){
          // console.log("clicked: " + clinicDataResponse.clinicData[i]?.clinicId);
          // setCurrectlySelectedClinicCalendar(clinicDataResponse.clinicData[i]);
          // console.log("currectlySelectedClinic from new refresh loop: ", clinicDataResponse.clinicData[i]);

          for(var j=0; j<clinicDataResponse?.clinicData[i].shiftData.length; j++){
            // console.log("clinicDataResponse.clinicData[i].shiftData: ", clinicDataResponse.clinicData[i].shiftData);
            if(clinicDataResponse?.clinicData[i].shiftData[j].shiftId === currentlySelectedShiftToEdit.shiftId){
              // console.log('Found currently selected shift in newly re-pulled clinic and shift data. Re-populating state with new shift data.');

              // TEST IF THIS WORKS....
              setSelectedShiftToEdit(clinicDataResponse?.clinicData[i].shiftData[j]);
            }
          }
        }

        // Set currently selected calendar
        if(clinicId == clinicDataResponse?.clinicData[i]?.clinicId){
          // console.log("clicked: " + clinicDataResponse.clinicData[i]?.clinicId);
          setCurrentlySelectedClinicCalendar(clinicDataResponse?.clinicData[i]);
          // console.log("currectlySelectedClinicCalendar from new refresh loop: ", clinicDataResponse.clinicData[i]);
        }
      }
    }
  }

  function getOrgData(organizationData) {
    try {
        return API.post("od-api", "/getOrganizationData", {
          body: organizationData,
        }).then((data) => {
          // console.log("get org data response: ", data);
          if(data.statusCode === 200){ // check response to see if succesful or not.
            const parsedBody = JSON.parse(data.body);
            // console.log("get org data response: ", parsedBody);
            return parsedBody;
          }else{
            console.log("error grabbing data from org data table.");
          }
        });
    } catch (e) {
        onError(e);
        console.log("ERROR: ", e);
    }
  };

  // REFACTOR: AND SPLIT THESE UP INTO INIDIVIDUAL CALLS.
  // Need to make sure this response doesn't get too big or will hit limit pulling all in one api call
  function getClinicAndShiftData(clinicData) {
    try {
        return API.post("od-api", "/getClinicDataAndShiftData", { 
          body: clinicData,
        }).then((data) => {
          // console.log("get clinic data response: ", data);
          if(data.statusCode === 200){ // check response to see if succesful or not.
            const parsedBody = JSON.parse(data.body);
            // console.log("get clinic data response: ", parsedBody);

            return parsedBody;
          }else{
            console.log("error grabbing data from clinic data table.");
          }
        });
    } catch (e) {
        onError(e);
        console.log("ERROR: ", e);
    }
  };

  // Get ALL Clinic Data and Shift Data - 
  function getALLClinicAndShiftData(params) {
    try {
        return API.post("od-api", "/getAllClinicDataAndShiftData", { 
          body: params,
        }).then((data) => {
          // console.log("get clinic data response: ", data);
          if(data.statusCode === 200){ // check response to see if succesful or not.
            const parsedBody = JSON.parse(data.body);
            // console.log("get ALL clinic data and shift data response: ", parsedBody);

            return parsedBody;
          }else{
            console.log("error grabbing data from clinic data table.");
          }
        });
    } catch (e) {
        onError(e);
        console.log("ERROR: ", e);
    }
  };

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    nav("/login");
  }
  
  function getItem(label, key, icon, children, type) {
    return {
      key, icon, children, label, type, 
    };
  }

  const PublicMenuItems = [
    getItem('Sign Up', 'signup', <TeamOutlined />),
    getItem('Log In', 'login', <LoginOutlined />)
  ]

  // NOTIFICATION POPOVER PANEL
  const AuthenticatedMenuItems = [
    getItem(isMobile ? 'Notifcations': '', 'notifications',

      isMobile ?
        <div style={{ disply: 'flex', justifyContent: isMobile ? '' : 'center', width: '40px', height: '60px', marginRight: '0px', marginTop: '2px' }}>

          {/* Only show notificiation badge on bell icon if there are notifications */}
          {notificationObject?.length > 0 ?
            <Badge color="#F759AB" count={notificationObject?.length} offset={[16, 0]}>
              <BellOutlined style={{ color: 'white', fontSize: '16px', cursor: 'pointer' }} />
            </Badge>
            :
            <BellOutlined style={{ color: 'white', fontSize: '16px', cursor: 'pointer' }} />
          }

        </div>
      :
      <Popover
          content={<NotificationContent 
              setNotificationPopoverVisibleTrigger={setNotificationPopoverVisibleTrigger} 
              notificationObject={notificationObject} 
              isMobile={isMobile} 
              userData={userData} />}
        title={<div style={{
          paddingTop: isMobile ? '0px' : '8px',
          paddingLeft: isMobile ? '0px' : '8px',
          paddingRight: isMobile ? '0px' : '8px',
        }}>Notifications</div>}
        trigger="click"
        open={notificationPopoverVisible}
        onOpenChange={setNotificationPopoverVisibleTrigger}
      >
        <div style={{ disply: 'flex', justifyContent: isMobile ? '' : 'center', width: '40px', height: '60px', marginRight: '0px', marginTop: '2px' }}>

          {/* Only show notificiation badge on bell icon if there are notifications */}
          {notificationObject?.length > 0 ?
            <Badge color="#F759AB" count={notificationObject?.length} offset={[16, 0]}>
              <BellOutlined style={{ color: 'white', fontSize: '16px', cursor: 'pointer' }} />
            </Badge>
            :
              <BellOutlined style={{ color: 'white', fontSize: '16px', cursor: 'pointer' }}/>
          }

        </div>
      </Popover>
  ),
    // getItem(isMobile ? 'Settings': '', 'settings', <SettingOutlined />),
    getItem(userData !== undefined ? userData.firstName : 'Profile', 'profile', <UserOutlined />), // Populate this with User Name
    getItem('Log Out', 'logout', <LogoutOutlined />)
  ]

  const clinicSubMenuItems = [
    getItem('Calendars', 'sub1', <CalendarOutlined />, 
    // Conditionally setting a notification Badge on the calendar button in this list, if there are notifications..
    loadingData ? [] : clinicCalendarList),
    {
      type: 'divider',
    },
    getItem(
      <>
        Contacts <Tag color="blue" style={{ marginLeft: '8px' }}>Coming Soon</Tag>
      </>, 
      'contacts', <TeamOutlined />
    ),
    getItem(
      <>
        Chat <Tag color="blue" style={{ marginLeft: '8px' }}>Coming Soon</Tag>
      </>, 
      'chat', <MailOutlined />
    ),
    getItem(
      <>
        Analytics <Tag color="blue" style={{ marginLeft: '8px' }}>Coming Soon</Tag>
      </>, 
      'analytics', <LineChartOutlined />
    ),
  ];

  const doctorSubMenuItems = [
    getItem('Find Shifts', 'findShifts', <SearchOutlined />),
    // getItem('My Calendar', 'myCalendar', <CalendarOutlined />),
    getItem(
      <>
        {/* Notification: dynamically set count between 1 and zero */}
        {/* Conditionally set this Badge, only show it if there are notifications */}
        { notificationObject?.length > 0 ?
          <Badge color="#F759AB" dot offset={[16, 16]}>
            My Calendar
          </Badge>
        :
          <>
            My Calendar
          </>
        }

      </>, 
      'myCalendar', <CalendarOutlined />
    ),
    // getItem('Favorite Clinics ? [] : clinicCalendarList), // Set this up later for "Favorite Clinics"
    // {
    //   type: 'divider',
    // },
    getItem(
      <>
        Contacts <Tag color="blue" style={{ marginLeft: '8px' }}>Coming Soon</Tag>
      </>, 
      'contacts', <TeamOutlined />
    ),
    getItem(
      <>
        Chat <Tag color="blue" style={{ marginLeft: '8px' }}>Coming Soon</Tag>
      </>, 
      'chat', <MailOutlined />
    ),
    getItem(
      <>
        Analytics <Tag color="blue" style={{ marginLeft: '8px' }}>Coming Soon</Tag>
      </>, 
      'analytics', <LineChartOutlined />
    ),
  ];

  // Function to show the drawer
  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  // Function to close the drawer
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  // based on which menu item is clicked, get that ID, and do something
  function handleMenuItemClick(key) {

    console.log("key clicked: " + key);

    if(key === "signup"){
      nav("/signup");
    }else if (key === "login"){
      nav("/login");
    }else if (key === "profile"){
      nav("/profile");
    }else if (key === "logout"){
      handleLogout();
    }else if (key === "chat"){
      // open chat page
    }else if (key === "contacts"){
      // open contacts page
    }else if (key === "myCalendar"){
      nav("/calendar");
    }else if (key === "findShifts"){
      nav("/");
    } else if (key === "notifications") {

      // console.log("clicked on notificaitons");
      // on mobile, open notifications modal on mobile.
      if(isMobile && notificationObject?.length > 0 ){
        setisMobileNotificationsModalOpen(true);
        console.log("notifications object: ", notificationObject);
      }
    }

    // pass the "currectlySelectedClinic" to props
    for(var i=0; i<clinicData.length; i++){
      if(key == clinicData[i].clinicId){
        console.log("clicked: " + clinicData[i].clinicId);
        setCurrentlySelectedClinicCalendar(clinicData[i]);
        // save currently selected calendar to sessionStorage
        sessionStorage.setItem("currentlySelectedClinicCalendar", clinicData[i].clinicId);
        console.log("currectlySelectedClinicCalendar: ", clinicData[i].clinicName);
        console.log(clinicData[i]);
        nav("/calendar");
      }
    }

    if(isMobile){
      onCloseDrawer();
    }
  };

  const closeMobileNotificationModal = () => {
    setisMobileNotificationsModalOpen(false);
  }


  // Onboarding Flow
  const OpenModal_InitialOnboard = () => {
    setIsWelcomModalOpen(true);
    setCreateOrgSequence(false);
    nav("/"); // They need to fill in their information
  };

  const OpenModal_CreateAnOrg = () => {
    console.log("inside OpenModal_CreateAnOrg");
    console.log("createOrgSequence bool: " + createOrgSequence);

    setCreateOrgSequence(true);
    handleCancel();
    setIsJoinOrgModalOpen(true);
    nav("/profile");
  };

  const OpenModal_JoinAnOrg = () => {
    handleCancel();
    setCreateOrgSequence(false);
    setIsJoinOrgModalOpen(true);
    nav("/profile");
  };

  const OpenModal_CreateAClinic = () => {
    handleCancel();
    setCreateOrgSequence(false);
    setIsCreateClinicModalOpen(true);
    nav("/profile");
  };

  // value is either a specific shift to edit, or the calendar date of the calendar square selected to create a new shift.
  const OpenModal_CreateOrEditAShift = (value) => {
    // pass an existing ShiftId to edit an existing shift, or don't pass one to create a new shift.
    if(value.clinicId != undefined){
      setSelectedShiftToEdit(value);
    }else{
      setSelectedCalendarDate(value);
      setSelectedShiftToEdit("");
    }
    handleCancel();
    setIsCreateOrEditShiftModalOpen(true);
  };

  const completeProfile = () => {
    setIsWelcomModalOpen(false);
    nav("/profile");

    // TODO:
    // NEED TO OPEN MODAL TO EDIT AND ADD DOCTOR INFORMATION IN THE PROFILE PAGE.. DONT LET THEM CLOSE THE MODAL...
    SetIsEditDoctorProfileInformationModalOpen(true);

  };

  const handleCancel = () => {
    setIsCreateClinicModalOpen(false); 
    setIsJoinOrgModalOpen(false); 
    setIsCreateOrEditShiftModalOpen(false); 
    // setIsGenericModalOpen(false); // force user to complete onboarding
    // setIsWelcomModalOpen(false); // force user to complete onboarding
    // SetIsEditDoctorProfileInformationModalOpen(false); // force user to complete onboarding
  };

  const OpenModal_Generic = (modalTitle, bodyText, buttonFunction, buttonText, showSpinner) => {
    setGenericModalTitle(modalTitle);
    setGenericModalBodyText(bodyText);
    setGenericModalButtonFunction(() => buttonFunction);
    setGenericModalButtonText(buttonText);
    setShowGenericModalShowSpinner(showSpinner);

    handleCancel();
    setIsGenericModalOpen(true);
  };

  return (
    <>
      {/* {contextHolder} */}
      {!isAuthenticating}
       {userData !== undefined ?
        <>
          <div className="App">
            <Layout>
              <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#0E2B4B' }}>
                {isMobile ? (
                  <>
                    <Button type="primary" onClick={showDrawer} style={{ marginLeft: '-26px'}}>
                      <div style={{ marginTop: '0px'}}>
                        <MenuOutlined />
                      </div>
                    </Button>
                    <Logo isMobile />
                    <Drawer title="Menu" placement="left" onClose={onCloseDrawer} width={280} open={visibleDrawer}>
                      
                      {/* Adjust menu depending on if you are a clinic or doctor, also need to adjust for mobile below. */}
                      {userData.userType == "clinicTeamMember" ? 
                        <Menu
                          mode="inline"
                          // defaultSelectedKeys={['1']}
                          defaultOpenKeys={['sub1']}
                          items={ isAuthenticated ? AuthenticatedMenuItems.concat(clinicSubMenuItems) : PublicMenuItems }
                          onClick={({ key }) => {handleMenuItemClick(key)}}
                        />
                      : 
                        <Menu
                          mode="inline"
                          // defaultSelectedKeys={['1']}
                          defaultOpenKeys={['sub1']}
                          items={ isAuthenticated ? AuthenticatedMenuItems.concat(doctorSubMenuItems) : PublicMenuItems }
                          onClick={({ key }) => {handleMenuItemClick(key)}}
                        />
                      }

                    </Drawer>
                  </>
                ) : (
                  <>
                    <Logo />
                    <Menu theme="dark" style={{backgroundColor:'#0E2B4B'}} mode="horizontal" items={ isAuthenticated ? AuthenticatedMenuItems : PublicMenuItems}
                        onClick={({ key }) => {handleMenuItemClick(key)}}
                    />
                  </>
                )}
              </Header>

              <Layout > 
                {isMobile ||  !isAuthenticated ? null  : (
                  <Sider width={260} style={{ background: '#fff', borderRight: '1px solid #E9EBEE' }}>

                    {/* Adjust menu depending on if you are a clinic or doctor, also need to adjust for desktop above. */}
                    {userData.userType == "clinicTeamMember" ? 
                      <Menu
                        mode="inline"
                        defaultOpenKeys={['sub1']}
                        style={{ height: '100%', borderRight: 0 }}
                        items={clinicSubMenuItems}
                        onClick={({ key }) => {handleMenuItemClick(key)}}
                      />
                    : 
                      <Menu
                        mode="inline"
                        defaultOpenKeys={['sub1']}
                        style={{ height: '100%', borderRight: 0 }}
                        items={doctorSubMenuItems}
                        onClick={({ key }) => {handleMenuItemClick(key)}}
                      />
                    }

                  </Sider>
                )}
                {/* <Layout className="mainPage" style={{ padding: isMobile ? '0px' : '24px 24px 0px 24px'}} > */}
                <Layout className="mainPage" style={{ padding: isMobile ? '0px' : '0px'}} >
                  <Content
                    style={{
                      // padding: 24,
                      padding: 0,
                      margin: 0,
                      minHeight: 280,
                      background: '#fff',
                    }}
                  >

                    {/*  */}
                    {/* REFACTOR: Update the app to use AppContext */}
                    {/* Uses React hook uesContext, which can act sorta like a Singleton, to not need to pass data all over the app, just make it globally accesible. */}
                    {/*  */}

                    <ErrorBoundary>
                      <AppContext.Provider
                        value={{ isAuthenticated, userHasAuthenticated }}

                      >
                        <Routes 
                          isMobile={isMobile} 
                          currentlySelectedClinicCalendar={currentlySelectedClinicCalendar}
                          loadingData={loadingData}
                          setLoadingData={setLoadingData}
                          userData={userData}
                          orgData={orgData}
                          clinicData={clinicData}
                          currentPage={currentPage}
                          interestedShifts={interestedShifts}
                          allDataForDoctorFindShiftsPage={allDataForDoctorFindShiftsPage}
                          doctorFormattedInterestedShiftDataForCalendar={doctorFormattedInterestedShiftDataForCalendar}
                          onLoginEvent={onLoginEvent}
                          OpenModal_CreateAnOrg={OpenModal_CreateAnOrg}
                          OpenModal_JoinAnOrg={OpenModal_JoinAnOrg}
                          OpenModal_CreateAClinic={OpenModal_CreateAClinic}
                          OpenModal_CreateOrEditAShift={OpenModal_CreateOrEditAShift}
                        />
                      </AppContext.Provider>
                    </ErrorBoundary>

                  </Content>
                </Layout>
              </Layout>
            </Layout>

            {/* Onboarding Modals */}
            <WelcomeModal
              // handleCancel={handleCancel}
              userData={userData} 
              isWelcomeModalOpen={isWelcomeModalOpen} 
              OpenModal_JoinAnOrg={OpenModal_JoinAnOrg} 
              // OpenModal_CreateAnOrg={OpenModal_CreateAnOrg} 
              OpenModal_CreateAClinic={OpenModal_CreateAClinic}
              completeProfile={completeProfile}
            ></WelcomeModal>

            <JoinOrgModal 
              handleCancel={handleCancel}
              userData={userData} 
              onLoginEvent={onLoginEvent} 
              setIsWelcomModalOpen={setIsWelcomModalOpen} 
              setIsJoinOrgModalOpen={setIsJoinOrgModalOpen}
              isJoinOrgModalOpen={isJoinOrgModalOpen}
            ></JoinOrgModal>

            <CreateClinicModal 
              handleCancel={handleCancel}
              userData={userData} 
              onLoginEvent={onLoginEvent}
              setIsWelcomModalOpen={setIsWelcomModalOpen}
              setIsCreateClinicModalOpen={setIsCreateClinicModalOpen}
              isCreateClinicModalOpen={isCreateClinicModalOpen}
              setIsGenericModalOpen={setIsGenericModalOpen}
            ></CreateClinicModal>

            <CreateOrEditShiftModal
              handleCancel={handleCancel}
              currentPage={currentPage}
              isMobile={isMobile}
              userData={userData} 
              orgData={orgData}
              clinicData={clinicData}
              onLoginEvent={onLoginEvent} 
              rePullClinicData={rePullClinicData}
              selectedCalendarDate={selectedCalendarDate}
              setIsCreateOrEditShiftModalOpen={setIsCreateOrEditShiftModalOpen}
              isCreateOrEditShiftModalOpen={isCreateOrEditShiftModalOpen}
              currentlySelectedShiftToEdit={currentlySelectedShiftToEdit}
              currentlySelectedClinicCalendar={currentlySelectedClinicCalendar}
              shiftsWithInterestedDoctors={shiftsWithInterestedDoctors} // For Clinics: pass in interested doctors to set up table for clinic to accept a doctor
              // interestedShifts={interestedShifts} // For Doctors: pass in interested shifts to set up table for doctors to view list of shifts they have applied to
            ></CreateOrEditShiftModal>

            {/* Adding Additional Doctor Information on onboarding flow */}
            <EditDoctorProfileModal
                handleCancel={handleCancel}
                isMobile={isMobile}
                currentPage={currentPage}
                userData={userData} 
                orgData={orgData}
                clinicData={clinicData}
                onLoginEvent={onLoginEvent} 
                isEditDoctorProfileInformationModalOpen={isEditDoctorProfileInformationModalOpen}
                SetIsEditDoctorProfileInformationModalOpen={SetIsEditDoctorProfileInformationModalOpen}
                setIsWelcomModalOpen={setIsWelcomModalOpen} 
            ></EditDoctorProfileModal>

            {/* Need to add clinic to your organization*/}
            <GenericModal
              handleCancel={handleCancel}
              modalTitle={genericModalTitle}
              isGenericModalOpen={isGenericModalOpen} 
              bodyText={genericModalBodyText}
              showButton='true'
              showLoadingSpinner={showGenericModalShowSpinner}  
              buttonFunction={genericModalButtonFunction} 
              buttonText={genericModalButtonText} 
              zIndex={1000}
            ></GenericModal>

            {/* Mobile Notifications Modal*/}
            <GenericModal
              handleCancel={closeMobileNotificationModal}
              modalTitle={"Notifications"}
              isGenericModalOpen={isMobileNotificationsModalOpen}
              bodyText={<NotificationContent 
                setNotificationPopoverVisibleTrigger={setNotificationPopoverVisibleTrigger} 
                notificationObject={notificationObject} 
                isMobile={isMobile} 
                userData={userData} />}
              showButton='true'
              showCancelButton='true'
              // showLoadingSpinner={showGenericModalShowSpinner}
              // buttonFunction={genericModalButtonFunction}
              buttonText={"Mark All Read"}
              zIndex={1000}
            ></GenericModal>

            {/* Loading Modal */}
            {/* ONLY FOR CALENDAR PAGE */}
            <GenericModal
              handleCancel={handleCancel}
              modalTitle={""}
              isGenericModalOpen={loadingData} 
              bodyText={""}
              showButton='false'
              show
              width='80px'
              showLoadingSpinner={true}
              buttonFunction={genericModalButtonFunction} 
              // buttonText={"One Second"} 
              zIndex={1000}
            ></GenericModal>

            <Footer isMobile={isMobile} />
          </div>
        </> 
       : 
        <></>
       }

    </>
  );
}

export default App;
