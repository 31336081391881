import React, { useState, useEffect } from 'react';
import "./Calendar.css";
// import Button from 'react-bootstrap/Button';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Button,  Card, Row, Col, Typography, Avatar, Badge } from "antd";
import interactionPlugin from '@fullcalendar/interaction'; 
import { Tag } from 'antd';

function Calendar(props) {

  const { 
    isMobile,
    currentlySelectedClinicCalendar,
    loadingData,
    userData, 
    clinicData,
    interestedShifts,
    OpenModal_CreateOrEditAShift,
    doctorFormattedInterestedShiftDataForCalendar
  } = props

  // Need to pull in the events here from shiftData
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (loadingData) {
      // console.log('Data is Loading.');
    } else {
      // Run the function to add the child div after the initial render
      addCalendarTitleChildDiv();

      if(userData.userType == "clinicTeamMember"){
        setEvents(currentlySelectedClinicCalendar?.formattedShiftData);
      }else{
        // console.log("doctorFormattedInterestedShiftDataForCalendar within Calendar.js: ", doctorFormattedInterestedShiftDataForCalendar);
        setEvents(doctorFormattedInterestedShiftDataForCalendar); // Pull formatted data from props into the calendar
      }

      // console.log("events: ", events);
    }
  }, [ loadingData, currentlySelectedClinicCalendar, clinicData, doctorFormattedInterestedShiftDataForCalendar]); 


  // Click on a black calendar square
  const handleCalendarDateClick = (arg) => {
    // Get the date of the calendar square day that was clicked
    // push this clicked date into the create shift modal
    if(userData.userType == 'clinicTeamMember'){
      console.log("clicked date: " + arg.dateStr);
      OpenModal_CreateOrEditAShift(arg.dateStr);
      // console.log('currentlySelectedClinicCalendar: ',  currentlySelectedClinicCalendar);
    }
  };

  // Clicked on an existing shift to edit
  const handleEventClick = (info) => {

    // this works the same for both doctors and clinics
    // console.log("clicked an existing event. now do things. Event: ", info.event._def.title);

    // on My Calendar for doctor side, there is no "currentlySelectedClinicCalendar".
    // check userType here and only set the below if a clinicTeamMember

    // For clinics, loop through all shifts in currentlySelectedClinicCalendar, to match and pass along ShiftData to the modal.
    let selectedShiftToEdit = {};
    if(userData.userType == "clinicTeamMember"){
      for(var i=0; i<currentlySelectedClinicCalendar.shiftData.length; i++){
        if(currentlySelectedClinicCalendar.shiftData[i].shiftId === info.event._def.title){
          selectedShiftToEdit = currentlySelectedClinicCalendar.shiftData[i];
        }
      }
      OpenModal_CreateOrEditAShift(selectedShiftToEdit);
    }
    else{

      // For Docotors loop through all the shifts in their InderestedShifts object, to match and pass along ShiftData to the modal.
      for(var i=0; i<interestedShifts.length; i++){
        if(interestedShifts[i].shiftId === info.event._def.title){
          selectedShiftToEdit = interestedShifts[i];
        }
      }

      OpenModal_CreateOrEditAShift(selectedShiftToEdit); 
    }

  };

  const AddShiftFromAddShiftButtonOutsideCalendar = () => {

    const today = new Date();
    const formattedDate = today.getFullYear() + '-' + 
      String(today.getMonth() + 1).padStart(2, '0') + '-' + 
      String(today.getDate()).padStart(2, '0');

    console.log(formattedDate);

    OpenModal_CreateOrEditAShift(formattedDate);
  }

  // Function to add a child div to the fc-toolbar-title div top left above calendar
  const addCalendarTitleChildDiv = (clinicName) => {
    
    const toolbarTitleDiv = document.querySelector('.fc-toolbar-title');
    if (toolbarTitleDiv) {
      // Check if the clinic calendar title div already exists
      let clinicCalendarTitle = toolbarTitleDiv.querySelector('.clinic-calendar-title');
      if (!clinicCalendarTitle) {
        // If it doesn't exist, create it
        clinicCalendarTitle = document.createElement('div');
        clinicCalendarTitle.className = 'clinic-calendar-title';
        toolbarTitleDiv.appendChild(clinicCalendarTitle);
      }

      if(userData.userType == "clinicTeamMember"){
        // Update the content of the clinic calendar title div
        clinicCalendarTitle.innerText = currentlySelectedClinicCalendar?.clinicName;
      }else{
        clinicCalendarTitle.innerText = 'My Calendar';
      }

    }

  };

  // Give tag color based on event status
  function createStatusTag(eventInfo){

    function tagColor(eventInfo){
      if(eventInfo.event.extendedProps.status === 'SCHEDULED'){
        return "#D3F7F8"
      }else if (eventInfo.event.extendedProps.status === 'PENDING'){
        return "#FFF7E5"
      }else if (eventInfo.event.extendedProps.status === 'AVAILABLE'){
        return "#C5FFC5"
      }
    };
    const tagColorResult = tagColor(eventInfo)
    // console.log("tagColorResult: ", tagColorResult);
    return(
      <Tag color={tagColorResult} style={{color: 'black'}}>
        {eventInfo.event.extendedProps.status}
      </Tag>
    )
  };


  const renderEventContent = (eventInfo) => {
    // console.log("renderEventContent running..");
    const { event } = eventInfo;
    // console.log("clicked on this event: ", eventInfo);
    // event.extendedProps includes full shiftData object of each associated shift to use here within renderEventContent.
    // allowing you to conditionally style each calendar event, add notifications, etc.
    // console.log("event.extendedProps: ", event.extendedProps); 

    return (
      <>
        <Row>
          <Col span={16}>
            <div className="fc-event-tag">
              {createStatusTag(eventInfo)}
            </div>
          </Col>
          {/* Conditionally render this notification tag based on current number of InterestedDoctors, and whether or not the shift is "scheduled" */}
          <Col span={8}>
            {userData.userType === "clinicTeamMember" 
            && event.extendedProps.shiftData.acceptedDoctor === undefined 
            && event.extendedProps.shiftData.interestedDoctors?.length > 0 ?
              <div className="fc-event-tag" style={{float: 'right'}}> 
                <Tag color={'#F759AB'} style={{color: 'white'}}> 
                  {event.extendedProps.shiftData.interestedDoctors?.length} </Tag>
              </div>
            : 
              <></>
            }
            {userData.userType === "individualDoctor"
            && event.extendedProps.status === "SCHEDULED" ?
              <div className="fc-event-tag" style={{float: 'right'}}> 
                {/* <Tag color={'#F759AB'} style={{color: 'white'}}> 1 </Tag> */}
                <Badge color="#F759AB" dot offset={[0, 0]} style={{marginRight: '16px'}}></Badge>
              </div>
            : 
              <></>
            }
          </Col>
        </Row>

        {/* <div className="fc-event-title-container"> */}
          {/* {eventInfo.event.title} */}
        {/* </div> */}
        <div className="fc-event-subtitle">
          {eventInfo.event.extendedProps.doctor}
        </div>
      </>
    );
  };

  return (
    <div className="Calendar" style={{ marginRight: isMobile ? '0px' : '20px', marginLeft: isMobile ? '0px' : '20px' }}>
      {!isMobile && userData.userType == 'clinicTeamMember' ?
        <Button className="primary-button button-size-small" onClick={AddShiftFromAddShiftButtonOutsideCalendar} style={{position:'absolute', right: '190px', marginTop: '12px'}}>Add Shift</Button>
      : 
        <></>
      }
      <FullCalendar
        height={isMobile ? 'calc(100vh - 340px)' : 'calc(100vh - 142px)'}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        // initialView="timeGridWeek"
        weekends={true}
        events={events}
        dateClick={handleCalendarDateClick}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
      />
      {isMobile && userData.userType == 'clinicTeamMember'  ?
        <Button className="primary-button button-size-small" onClick={AddShiftFromAddShiftButtonOutsideCalendar} style={{float: 'right', marginTop:'20px', marginRight:'20px'}}>Add Shift</Button>
      : 
        <></>
      }

    </div>

  );
};

export default Calendar;